import PropTypes from "prop-types";
import { publicPagesData, privatePagesData } from "../constants/pagesData";

export default function GetLayoutData(pathname, startLink, isPrivateRoute) {
  const segments = pathname.split("/");
  const key = segments.pop();
  const dynamicData = (isPrivateRoute ? privatePagesData : publicPagesData).get(key || "/") || publicPagesData.get("*");

  const { breadcrumb, pageTitle, subTitle, name, type, description, keywords } = dynamicData;
  const breadcrumbItems = [];
  if (key) {
    breadcrumbItems.push({ key: "Tramway", textValue: "Tramway", link: "../" + startLink });
    breadcrumbItems.push({ key, textValue: breadcrumb.textValue, link: null });
  }

  return { breadcrumbItems, pageTitle, subTitle, name, type, description, keywords };
}

GetLayoutData.propTypes = {
  pathName: PropTypes.string,
  startLink: PropTypes.string,
  isPrivateRoute: PropTypes.oneOfType([
    PropTypes.shape({
      params: PropTypes.object.isRequired,
      pathname: PropTypes.string.isRequired,
      pathnameBase: PropTypes.string.isRequired,
      pattern: PropTypes.object.isRequired,
    }),
    PropTypes.oneOf([null]),
  ]),
};
