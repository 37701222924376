import PropTypes from "prop-types";
import { Image } from "@adobe/react-spectrum";

export default function RandomPicture({ size, color, random, id, rgpd }) {
  return (
    <Image
      height={rgpd ? "size-3600" : "size-2000"}
      slot="hero"
      id="map"
      alt={
        rgpd
          ? "un image repésentant une personne qui marche en costume cravate"
          : `Une image aléatoire représentant la beauté de la nature`
      }
      src={
        id
          ? `https://picsum.photos/id/${id}/${size}/${size}`
          : `https://picsum.photos/${size}.webp?${color}&random=${random}`
      }
      objectFit="cover"
    />
  );
}

RandomPicture.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  random: PropTypes.number,
  id: PropTypes.number,
  rgpd: PropTypes.bool,
};
