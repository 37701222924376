import PropTypes from "prop-types";
import { View } from "@adobe/react-spectrum";

function PrivateLayout({ children, noBorders }) {
  return (
    <View
      data-pagefind-ignore
      width={{ base: "100%", L: "70%", M: "100%", S: "100%" }}
      borderWidth={noBorders ? "" : { base: "thin", L: "thin", M: "thin", S: "thin" }}
      borderColor="dark"
      borderRadius="medium"
      padding={{ base: "size-100", L: "size-250", M: "size-250", S: "size-250" }}
      minHeight="size-6000"
    >
      {children}
    </View>
  );
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
  noBorders: PropTypes.bool,
};

export default PrivateLayout;
