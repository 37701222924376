import { PUBLIC_ROUTES } from "./publicroutes";
import { PRIVATE_ROUTES } from "./privateroutes";
import { BOTTOM_NAV } from "./theme";
import { MENU_NAV } from "./nav";

const commonPageData = {
  breadcrumb: { textValue: "" },
  pageTitle: "",
  subTitle: "",
  name: "Tramway",
  type: "website",
  description:
    "Tramway est fait pour les 'Roots'. Si vous cherchez une connexion authentique, vous êtes au bon endroit. Ce n’est pas un simple site de rencontre pour adultes de tous horizons, mais un tiers-lien où la nature, la durabilité et l’authenticité sont célébrées. Ici, on crée des souvenirs qui durent, dans un esprit de simplicité et de respect.",
  keywords:
    "Tramway, site de rencontre, alternative attitude, rencontre authentique, connexion authentique, rencontre durable, simplicité, respect, communauté Roots, nature et durabilité, tiers-lien, souvenirs durables, rencontre alternative",
};

const publicPagesData = new Map([
  [
    PUBLIC_ROUTES.index,
    {
      ...commonPageData,
      pageTitle: "Tramway • Alternative attitude • Site de rencontre accessible pour les Roots",
      description:
        "Tramway est fait pour les 'Roots'. Si vous cherchez une connexion authentique, vous êtes au bon endroit. Ce n’est pas un simple site de rencontre pour adultes de tous horizons, mais un tiers-lien où la nature, la durabilité et l’authenticité sont célébrées. Ici, on crée des souvenirs qui durent, dans un esprit de simplicité et de respect.",
    },
  ],
  [
    PUBLIC_ROUTES.accessibilite,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.accessibilite },
      pageTitle: "Accessibilité",
      subTitle:
        "Nous travaillons pour concevoir une plateforme de services numériques accessibles. Et surtout, nous aimons ce que nous faisons !",
      description:
        "Nous sommes une équipe passionnée. Nous travaillons pour concevoir une plateforme de services numériques accessibles. Et surtout, nous aimons ce que nous faisons !",
    },
  ],
  [
    PUBLIC_ROUTES.aide,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.aide },
      pageTitle: "Sécurité et autres petites joies",
      subTitle: "Lorsque les règles du jeu sont connues dès le départ, il est bien plus facile d’agir.",
      description:
        "Nos conseils pour assurer votre sécurité et naviguer sur internet et sur tramway.life, site de rencontres alternatif dans le cadre de rencontres bio.",
    },
  ],
  [
    PUBLIC_ROUTES.avis,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.avis },
      pageTitle: "Namaste",
      subTitle:
        "  La culture de Tramway est la somme des expériences des personnes qui la créent et y participent. Nous préférons l'expérience participative. S'il vous plaît, faites un don. Laissez-nous une belle évaluation. Merci.",
      description:
        "  La culture de Tramway est la somme des expériences des personnes qui la créent et y participent. Nous préférons l'expérience participative. S'il vous plaît, faites un don. Laissez-nous une belle évaluation. Merci.",
    },
  ],
  [
    PUBLIC_ROUTES.connexion,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.connexion },
      pageTitle: "Connexion",
      subTitle: "La connexion à tous les services Tramway est possible avec les mêmes identifiants.",
      description: "Ça fait plaisir de te revoir !",
    },
  ],
  [
    PUBLIC_ROUTES.contact,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.contact },
      pageTitle: "On papote ?",
      subTitle: "Formulaire de contact",
      description:
        "Vous avez des questions concernant Tramway ? Vous êtes au bon endroit. Vous voulez seulement nous faire un petit coucou ? Vous êtes également au bon endroit.",
    },
  ],
  [
    PUBLIC_ROUTES.creerCompte,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.creerCompte },
      pageTitle: "Créer ton compte",
      subTitle: "La création de compte est possible avec un simple email.",
      description: "Ton aventure sur Tramway commence !",
    },
  ],

  [
    PUBLIC_ROUTES.manifesto,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.manifesto },
      pageTitle: "Manifesto",
      subTitle: "Tramway est propulsé par Bluecornflakes • digital native • Une micro-perma-entreprise. Sacrebleu !",
      description: "Tramway est propulsé par Bluecornflakes une micro perma-entreprise.",
    },
  ],
  [
    PUBLIC_ROUTES.sitemap,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.sitemap },
      pageTitle: "Plan du site",
      subTitle:
        "Un plan du site est toujours utile pour s’y orienter, visualiser l'ensemble des contenus et la taille du site.",
      description: "Carte du site web",
    },
  ],
  [
    PUBLIC_ROUTES.glossaire,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.glossaire },
      pageTitle: "Le glossaire du Tramway",
      subTitle: "Faciliter l'utilisation du Tramway, comprendre les contenus sectoriels ou à caractère technique.",
      description: "Un lexique ou un glossaire adapté explique le vocabulaire sectoriel ou technique.",
    },
  ],
  [
    PUBLIC_ROUTES.FAQ,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.faq },
      pageTitle: "FAQ",
      subTitle: "Questions fréquentes",
      description: "Une liste de questions fréquemment posées accompagnées de leurs réponses. ",
    },
  ],
  [
    PUBLIC_ROUTES.error404,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: "Erreur 404" },
      pageTitle:
        "Tiens donc, une erreur 404 chez Tramway. Votre connexion fonctionne, le site fonctionne mais la page demandée n’existe pas.",
      subTitle: "Erreur 404 : Page inexistante.",
      description:
        "Une erreur 404 survient lorsque la page demandée par l'utilisateur n'est pas trouvée sur le serveur. Cela peut être dû à une URL incorrecte, une ressource supprimée ou déplacée, ou des problèmes de configuration du serveur.",
    },
  ],
  [
    PUBLIC_ROUTES.error403,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: "Erreur 403" },
      pageTitle:
        "Tiens donc, une erreur 403 sur le Tramway. Votre connexion est stable, le site est opérationnel, mais il semble que vous n'ayez pas l'autorisation d'accéder à cette page.",
      subTitle: "Erreur 403 : Accès refusé par le serveur.",
      description:
        "Accès restreint : La ressource demandée peut être restreinte à un certain groupe d'utilisateurs ou à des utilisateurs authentifiés. Si l'utilisateur ne fait pas partie de ce groupe ou s'il n'est pas authentifié, une erreur 403 sera renvoyée.",
    },
  ],
  [
    PUBLIC_ROUTES.mentionsLegales,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.mentionsLegales },
      pageTitle: "Mentions légales",
      subTitle:
        "Tramway est un site web et aussi une application progressive à installer depuis le navigateur opensource chromium comme une véritable application !",
      description:
        "Éditeur Responsable de la publication : Sylvain Ducoulombier. Bluecornflakes (France), N° SIRET : 520 820 549 000 43, Entreprise non-diffusible selon L’article A123-96 du code de Commerce. Contact : onpapote@tramway.life, Hébergement : CLOUDFLARE pour le site et MAILO pour les courriels... ",
    },
  ],
  [
    PUBLIC_ROUTES.feed,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.feed },
      pageTitle: "Fil d'actualité X",
      subTitle: "Suivez-nous ici ! @tramway__RDV",
      description:
        "Découvrez toute l'Actualités du Tramway sur Twitter. Tramway est reconnu comme le premier site de rencontres dédié aux amoureux du bio et de la nature, notre site fait régulièrement l’objet de reportages dans les plus grands médias - militant des alternatives durables et de l’amour durable.",
    },
  ],
  [
    PUBLIC_ROUTES.CGV_CGU,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: MENU_NAV.CGV_CGU },
      pageTitle: "Conditions générales de vente et d’utilisation",
      subTitle: "CGV & CGU du Tramway.",
      description:
        "Les Conditions Générales de Vente et d'Utilisation (CGV &CGU) définissent les règles et obligations pour l'utilisation de Tramway. Elles précisent les droits et responsabilités des utilisateurs, les modalités de paiement et de livraison, ainsi que les politiques de confidentialité et de sécurité.",
    },
  ],
]);

const privatePagesData = new Map([
  [
    PRIVATE_ROUTES.accueil,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: BOTTOM_NAV.accueil },
      pageTitle: "Bienvenue",
      subTitle: "Ton petit écosystème.🥬🥕🐱",
      description:
        "Les dernières nouvelles concernant ton profil de membre Tramway. Découvre les visites reçues, les membres en ligne, les anniversaires du jour, la liste des indésirables... Reste informé!",
    },
  ],
  [
    PRIVATE_ROUTES.flash,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: BOTTOM_NAV.flash },
      pageTitle: "Cœurs d'artichaut",
      subTitle: "Pour sûr, les artichauts sont de volages amoureux.",
      description: "Les Flashs que vous avez reçus. Les Cœurs d'artichaut qui Pour sûr, sont de volages amoureux.",
    },
  ],
  [
    PRIVATE_ROUTES.messages,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: BOTTOM_NAV.message },
      pageTitle: "Le Petit Courrier",
      subTitle: "...des histoires d'Amour !",
      description: "Le Petit Courrier des histoires d'Amour",
    },
  ],
  [
    PRIVATE_ROUTES.private,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: "Contrôle des billets" },
      pageTitle: "Contrôle des billets, s'il vous plaît !",
      subTitle: "La double authentification est requise",
      description: "Contrôle des billets, s'il vous plaît ! Double authentification requise",
    },
  ],
  [
    PRIVATE_ROUTES.profile,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: "Mon profil" },
      pageTitle: "Mon profil",
      subTitle: "Prévisualiser et modifier mon profil. 🪘",
      description: "Prévisualiser et modifier mon profil. 🪘",
    },
  ],
  [
    PRIVATE_ROUTES.cercles,
    {
      ...commonPageData,
      breadcrumb: { ...commonPageData.breadcrumb, textValue: BOTTOM_NAV.cercles },
      pageTitle: "Ce qui me meut...",
      subTitle: "Rejoindre des cercles vous permet de trouver des membres du Tramway partageant les mêmes idées.",
      description:
        "Ce qui me meut...Rejoindre des cercles vous permet de trouver des membres du Tramway partageant les mêmes idées.",
    },
  ],
]);

export { publicPagesData, privatePagesData };
