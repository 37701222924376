import { useState, useCallback } from "react";

const useEmailValidation = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(null);

  const handleEmailChange = useCallback((value) => {
    const normalizedEmail = value.trim().toLowerCase();
    const isValid = /^[a-z0-9._%+-]{0,41}@[a-z0-9.-]+\.[a-z]{2,}$/.test(normalizedEmail);
    setEmail(normalizedEmail);
    setIsEmailValid(isValid);
  }, []);

  return { email, isEmailValid, handleEmailChange };
};

export default useEmailValidation;
