const CONTACT_FORM_OPTIONS = [
  { id: "Presse", name: "Presse : Inclure Tramway dans votre prochain article ou blog ?" },
  {
    id: "Histoires",
    name: "Livre d'Or : As-tu rencontré la personne la plus importante de ta vie sur Tramway ? Raconte-nous.",
  },
  { id: "Partenariats", name: "Partenariats : Intéressé(e) par un partenariat avec Tramway ?" },
  { id: "Autre", name: "Autre" },
  { id: "Support", name: "Signaler un Bug" },
  { id: "Modération", name: "Solliciter le modérateur" },
  { id: "SAV", name: "Solliciter le Service Après Vente" },
  { id: "RGPD", name: "Protection des données personnelles" },
];

const CONTACT_FORM_KEY = {
  SUPPORT: "Support",
  PARTNERSHIP: "Partenariats",
  PRESS: "Presse",
  SUCCESS_STORIES: "Histoires",
  TERMS: "RGPD",
  SAV: "SAV",
  OTHER: "Autre",
  MODERATION: "Modération",
};

export { CONTACT_FORM_OPTIONS, CONTACT_FORM_KEY };
