import PropTypes from "prop-types";
import { IllustratedMessage, Heading, Content } from "@adobe/react-spectrum";
import NoSearchResults from "@spectrum-icons/illustrations/NoSearchResults";

function NoSearchResultsIllustratedMessage({ message }) {
  return (
    <IllustratedMessage width="100%" margin="size-250" padding="size-250">
      <NoSearchResults />
      <Heading>{message}</Heading>
      <Content>
        Tramway • courant alternatif <span title="Marque déposée">®</span>
      </Content>
    </IllustratedMessage>
  );
}

NoSearchResultsIllustratedMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NoSearchResultsIllustratedMessage;
