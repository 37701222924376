import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Item, Link } from "@adobe/react-spectrum";

function Breadcrumb({ breadcrumbItems }) {
  const navigate = useNavigate();

  return (
    <Breadcrumbs size="M" marginTop="size-100">
      {breadcrumbItems.map((item) => (
        <Item key={item?.key} textValue={item?.textValue}>
          {item?.link && (
            <Link variant="secondary" onPress={() => navigate(item?.link)}>
              {item.textValue}
            </Link>
          )}

          {!item.link && item?.textValue}
        </Item>
      ))}
    </Breadcrumbs>
  );
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.arrayOf(PropTypes.object),
};

export default Breadcrumb;
