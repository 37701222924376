import { useContext } from "react";
import Moon from "@spectrum-icons/workflow/Moon";
import Light from "@spectrum-icons/workflow/Light";
import { Button, useProvider } from "@adobe/react-spectrum";
import { COLORTHEMES } from "../constants/theme";
import UserAccessibility from "../contexts/UserAccessibility";

export default function UserShareTheAppByMailButton() {
  const { colorScheme } = useProvider();
  const { toggleTheme } = useContext(UserAccessibility);

  const getButtonContent = () => {
    const icon = colorScheme === COLORTHEMES.light ? <Moon /> : <Light color="notice" />;

    return <>{icon}</>;
  };

  return (
    <>
      <Button
        width="size-600"
        variant="primary"
        aria-label={`Rendre un theme ${colorScheme === COLORTHEMES.light ? "Sombre" : "Clair"}`}
        style="outline"
        onPress={() => toggleTheme()}
      >
        {getButtonContent()}
      </Button>
    </>
  );
}
