import { v4 as uuidv4 } from "uuid";

const CGV = Object.freeze([
  {
    id: uuidv4(),
    title: "Article 1 : Objet – Application des Conditions Générales de Vente",
    children: [
      {
        id: uuidv4(),
        text: "Les présentes Conditions Générales de Vente régissent les contrats en ligne par lesquels Tramway propose à distance et par voie électronique la fourniture de services dans le cadre de son activité. Ces Conditions Générales de Vente s'appliquent, sans restriction ni réserve, à l'ensemble des services proposés par Tramway.",
        title: "Champ d'application",
      },
      {
        id: uuidv4(),
        text: "Elles ont été rédigées pour formaliser la transaction commerciale et s'appliquent à la vente de prestations de services par Tramway sur son site accessible à l'adresse suivante : www.tramway.life. Les conditions générales de vente sont mises à la disposition du membre sur la page d'accueil du site Internet.",
        title: "Accessibilité des CGV",
      },
      {
        id: uuidv4(),
        text: "Le membre peut les consulter librement en cliquant sur le lien 'CGV & CGU' pour en prendre connaissance avant de s'engager dans le processus de commande. Le fait pour un membre de confirmer sa commande sur le site Internet (en cliquant sur le bouton 'valider la commande') implique l'adhésion pleine et entière aux présentes Conditions Générales de Vente et aux Conditions Générales d'Utilisation du site, à l'exclusion de tout autre document.",
        title: "Acceptation des CGV",
      },
      {
        id: uuidv4(),
        text: "Sauf preuve contraire, les données enregistrées par Tramway constituent la preuve de l'ensemble des transactions. Les prestations de service présentées sur le présent site Internet sont proposées à la vente pour les territoires francophones, notamment : France métropolitaine, Belgique, Suisse et Canada ; cette liste est non exhaustive.",
        title: "Preuve des transactions et territoires concernés",
      },
      {
        id: uuidv4(),
        text: "La validation de la commande de services par le membre vaut acceptation sans réserve des présentes Conditions Générales de Vente.",
        title: "Validation de la commande",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 2 : Protection des mineurs",
    children: [
      {
        id: uuidv4(),
        text: "Le membre certifie, en remplissant le formulaire d'inscription, avoir l'age légal, en France cela correspond à dix-huit (18) ans révolus à la date de son inscription et avoir la capacité juridique en cochant la case appropriée. Tramway se réserve le droit de demander une preuve d'âge et de capacité juridique à tout moment. L'accès aux services de Tramway est strictement réservé aux personnes majeures.",
        title: "Certification d'âge et capacité juridique",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 3 : Modalités d'inscription en tant que membre et caractéristiques essentielles des prestations",
    children: [
      {
        id: uuidv4(),
        text: "Toute personne majeure qui s'inscrit en renseignant le formulaire d'inscription sur le site après avoir accepté les conditions générales de vente et les conditions générales d'utilisation du site est : 'Membre'.",
        title: "Définition de membre",
      },
      {
        id: uuidv4(),
        text: "Les membres de Tramway peuvent accéder gratuitement au site (hormis le coût de la connexion Internet de leur fournisseur etc. Se référer au CGU) munis de leur adresse email et de leur code de double authentification si cela est activé par le membre. Chaque membre s'engage à conserver son email et ce code aléatoire strictement confidentiels.",
        title: "Accès gratuit et confidentialité",
      },
      {
        id: uuidv4(),
        text: "Les services fournis par Tramway sont ceux proposés sur le site Internet tramway.life et décrits dans la fiche 'Abonnement' de chaque service.",
        title: "Description des services",
      },
      {
        id: uuidv4(),
        text: "Les prestations diffèrent suivant que le membre a la qualité de membre inscrit ou de membre abonné. Les membres inscrits peuvent accéder à certaines fonctionnalités de base, tandis que les membres abonnés bénéficient de services supplémentaires et exclusifs, tels que définis sur dans les Offres du site.",
        title: "Différences entre membre inscrit et membre abonné",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 4 : Membre inscrit",
    children: [
      {
        id: uuidv4(),
        text: "En vous inscrivant, vous créez votre profil pour sa parution en ligne, votre adresse e-mail ne sera pas divulguée aux autres membres.",
        title: "Confidentialité de l'email",
      },
      {
        id: uuidv4(),
        text: `Le membre inscrit peut : Créer une fiche-profil pour la faire publier, Faire une annonce et déposer des photos, Consulter la liste des membres, Faire des recherches de membres, Consulter les fiches des membres, Détenir une liste des membres indésirables consistant à bloquer les membres choisis par l'abonné, Constituer une liste de membres préférés : favoris, Choisir de recevoir ou de ne pas recevoir les notifications par email des flashs, visites, courriers...`,
        title: "Fonctionnalités pour les membres inscrits",
      },
      {
        id: uuidv4(),
        text: "Tramway ne peut pas garantir la bonne réception des notifications par email",
        title: "Limitation de garantie sur les emails",
      },
      {
        id: uuidv4(),
        text: "Les membres inscrits visibles sur le site peuvent s'abonner lorsqu'ils le désirent, par ce fait, ils ne sont pas forcément à jour de leur abonnement. Par conséquent, Tramway ne peut pas garantir la lecture, ni la réponse des courriers envoyés par des membres abonnés à destination de membres non à jour de leur abonnement.",
        title: "Abonnement et limitations de messagerie : courrier",
      },
      {
        id: uuidv4(),
        text: "Le membre non abonné ne pourra pas lire les messages provenant des autres membres, ni écrire des messages aux autres membres.",
        title: "Restrictions pour les membres non abonnés",
      },
      {
        id: uuidv4(),
        text: "Cependant, tous les messages des membres inscrits ou abonnés sont conservés dans leur boîte aux lettres pendant une année maximum. Après ce délai d'un an, les messages disparaîtront définitivement.",
        title: "Durée de conservation des messages",
      },
      {
        id: uuidv4(),
        text: "Chaque membre, qu'il soit inscrit ou abonné, sera averti par e-mail de la réception de messages dans son courrier électronique mais pour pouvoir lire ou écrire des messages, il devra s'abonner.",
        title: "Notification des messages et nécessité d'abonnement",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 5 : Membre abonné",
    children: [
      {
        id: uuidv4(),
        text: "Le Membre abonné est un membre inscrit à jour de son règlement.",
        title: "Définition du membre abonné",
      },
      {
        id: uuidv4(),
        text: "L'abonnement donne droit aux services supplémentaires suivants :\n- La consultation et l'envoi des messages aux autres membres via la messagerie appelée 'Courrier du Tramway'.\n- La participation aux tchats et autres fonctionnalités interactives.\nNota : la consultation des messages déjà lus est accessible exclusivement au membre abonné, c'est-à-dire un membre à jour de son règlement.",
        title: "Services supplémentaires pour les membres abonnés",
      },
      {
        id: uuidv4(),
        text: "Un membre inscrit est un membre ayant créé un compte profil gratuitement. Les membres inscrits peuvent parcourir les profils, mais ne peuvent pas utiliser les services de messagerie et de tchat.\nUn membre abonné est un membre ayant réglé un abonnement. Les abonnés ont accès à des services supplémentaires, notamment la messagerie 'Courrier du Tramway', permettant la consultation et l'envoi de messages.",
        title: "Article 5 bis : Membre inscrit ou membre abonné",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 6 : Notifications et courriels pour les membres inscrits ou abonnés",
    children: [
      {
        id: uuidv4(),
        text: `Les membres consentent à la réception des courriels et notifications suivants :
        1. De l'arrivée de nouveaux messages
        2. Des « coups de cœurs » dans leurs boîtes aux lettres
        3. De la visite d'un membre de leurs fiches profils
        4. Des invitations au tchat privé envoyées par les autres membres.`,
        title: "Types de notifications",
      },
      {
        id: uuidv4(),
        text: `Les membres ont la possibilité de gérer leurs notifications sur leur Espace Membre.`,
        title: "Gestion des notifications",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 7 : Messages additionnels pour les membres",
    children: [
      {
        id: uuidv4(),
        text: `Les membres peuvent éventuellement recevoir les messages suivants :
        1. Des profils des membres ayant des affinités avec eux
        2. Un rappel de l'expiration de leur abonnement
        3. Des propositions de renouvellement de leur abonnement
        4. Des communiqués ou des appels à témoignages`,
        title: "Types de messages additionnels",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 8 : Service courrier du Tramway",
    children: [
      {
        id: uuidv4(),
        text: `Le site Tramway ne garantit pas l'accès et l'utilisation du courrier (chat, messagerie) aux abonnés. Le service courrier est accessible uniquement pour les membres qui sont à jour de leur règlement d'abonnement.`,
        title: "Conditions d'accès au courrier",
      },
    ],
  },

  {
    id: uuidv4(),
    title: "Article 9 : Modification du profil du membre",
    children: [
      {
        id: uuidv4(),
        text: `Tout membre inscrit sur le site apparaissant sur la liste des membres peut modifier certaines informations de son profil depuis sa page "Profil". Cependant, les informations suivantes ne sont pas modifiables : pseudo`,
        title: "Conditions de modification du profil",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 10 : Suppression du profil du membre",
    children: [
      {
        id: uuidv4(),
        text: `Tout membre inscrit non abonné peut clôturer son compte depuis sa page "Compte". Toutes les informations et messages des membres désinscrits ou résiliés disparaissent définitivement, devenant ainsi inaccessibles aux autres membres.`,
        title: "Procédure de clôture du profil",
      },
      {
        id: uuidv4(),
        text: `Tout membre abonné peut clôturer définitivement son profil, même s'il possède un abonnement en cours non remboursable. Aucun remboursement n'est accordé pour la période restante de l'abonnement en cours en cas de clôture de compte par le membre pendant sa période d'abonnement. La suppression du compte équivaut à une résiliation sans remboursement si le membre a utilisé la messagerie, le chat ou le courrier pendant son abonnement. La clôture du profil est irréversible, bien qu'il puisse être réactivé dans un délai raisonnable de quelques semaines. Les informations associées aux profils clôturés sont définitivement perdues. Si un membre ne se connecte pas pendant plus d'un an, son profil est automatiquement clôturé, ce qui entraîne la disparition de toutes ses informations et de ses messages.`,
        title: "Procédure de clôture du profil pour les membres abonnés",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 11 : Durée de l'abonnement",
    children: [
      {
        id: uuidv4(),
        text: `Un forfait de 2 mois est proposé, renouvelable par le membre lui-même. Aucune reconduction tacite n'est proposée. Les abonnements ne sont pas automatiquement reconduits, offrant ainsi au membre la liberté de se réabonner à sa convenance. Aucun prélèvement automatique n'est effectué par Tramway. Le tarif de l'abonnement correspond à la durée totale choisie par le membre et est clairement indiqué sur la page d'abonnement de l'espace membre. Tramway n'accorde pas d'abonnement gratuit ni de prolongation d'abonnement, quelles que soient les raisons invoquées par le membre ou toute autre personne. Par conséquent, aucune demande d'abonnement gratuit ne sera acceptée.`,
        title: "Durée et renouvellement de l'abonnement",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 12 : Règlement de l'abonnement",
    children: [
      {
        id: uuidv4(),
        text: `Pour régler votre abonnement, Tramway offre une variété de méthodes de paiement sécurisées via Stripe, notre partenaire de paiement. Vous pouvez choisir parmi les options suivantes :
        - Carte bancaire
        - PayPal si disponnible.
        - Apple Pay si disponnible.
        - Google Pay si disponnible.
        - Autres méthodes de paiement acceptées par Stripe si elles sont disponnibles.`,
        title: "Méthodes de paiement",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 13 : Droit de rétractation",
    children: [
      {
        id: uuidv4(),
        text: "Tramway accorde au membre un délai de rétractation de 14 jours francs à compter de la souscription de son abonnement, conformément à la loi. Pendant cette période, le membre peut exercer son droit de rétractation sans pénalité ni motif.",
        title: "Délai de rétractation",
      },
      {
        id: uuidv4(),
        text: "Conformément à l'article L 121-20-2-1° du Code de la Consommation français, le droit de rétractation ne peut plus être exercé dès lors que le membre a accédé aux fonctionnalités concernées par l'abonnement.",
        title: "Exceptions au droit de rétractation",
      },
      {
        id: uuidv4(),
        text: "De plus, si le membre utilise le service de messagerie/courrier/chat avant la fin du délai de rétractation, il renonce expressément à son droit de rétractation, conformément à l'article L221-28 du code de la consommation. L'utilisation des services pendant le délai de rétractation entraîne automatiquement la renonciation à ce droit.",
        title: "Renonciation au droit de rétractation",
      },
      {
        id: uuidv4(),
        text: "En cas de rétractation, le remboursement correspond au montant réglé, déduit des frais bancaires. Le remboursement entraîne la suppression définitive du profil.",
        title: "Remboursement et suppression du profil",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 14 : Conclusion du contrat en ligne",
    children: [
      {
        id: uuidv4(),
        text: `Avant de confirmer son abonnement, le membre vérifie attentivement les détails de sa commande, y compris les services sélectionnés, le mode de paiement et le prix total toutes taxes comprises, et corrige toute erreur éventuelle.`,
        title: "Vérification de la commande",
      },
      {
        id: uuidv4(),
        text: `Le contrat entre Tramway et le membre sera définitivement conclu lorsque le membre aura confirmé son acceptation du contrat en cliquant sur le bouton de confirmation, après avoir accepté les conditions générales de vente et les conditions générales d'utilisation.`,
        title: "Conclusion du contrat",
      },
      {
        id: uuidv4(),
        text: `Jusqu'au moment du paiement, le membre conserve la possibilité de modifier les services commandés.`,
        title: "Modification de la commande",
      },
      {
        id: uuidv4(),
        text: `Une fois la commande confirmée, Tramway envoie au membre un e-mail d'accusé de réception de la commande pour confirmer que sa demande a été prise en compte.`,
        title: "Accusé de réception",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 15 : Paiement",
    children: [
      {
        id: uuidv4(),
        text: `Le membre s'engage à régler le prix de l'abonnement en totalité, au moment de la commande.`,
        title: "Engagement de paiement",
      },
      {
        id: uuidv4(),
        text: `Le paiement de l'abonnement ne sera considéré comme définitif qu'après l'encaissement effectif par Tramway des sommes dues. En conséquence, Tramway ne sera pas tenue de fournir les services commandés tant que le paiement intégral n'aura pas été reçu.`,
        title: "Confirmation du paiement",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 16 : Fourniture des prestations",
    children: [
      {
        id: uuidv4(),
        text: `La fourniture des prestations d'abonnement débute huit jours après la date de paiement. En cas d'utilisation des fonctionnalités dès l'encaissement du paiement, le membre renonce à son droit de rétractation.`,
        title: "Date de fourniture des prestations",
      },
      {
        id: uuidv4(),
        text: `Les services fournis par Tramway sont conformes à la réglementation en vigueur en France. Il incombe au membre de vérifier la conformité de ces services à la législation de son pays.`,
        title: "Conformité des services",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 17 : Limitation de responsabilité",
    children: [
      {
        id: uuidv4(),
        text: "Tramway agit en tant qu'intermédiaire technique et n'est pas responsable des rencontres entre membres. Le membre utilise le site à ses propres risques et est seul responsable de ses interactions avec d'autres membres. Tramway ne peut vérifier l'identité de chaque membre et ne peut être tenue responsable d'éventuelles inexactitudes. Tramway n'assume aucune obligation de résultat et se décharge de toute responsabilité quant aux rencontres physiques entre ses membres.",
        title: "Responsabilité de Tramway",
      },
      {
        id: uuidv4(),
        text: "Tramway ne peut être tenu responsable des dommages directs ou indirects résultant de l'utilisation du site. De plus, Tramway décline toute responsabilité en cas de fausses déclarations faites par un membre ou lors de rencontres entre membres ou non-membres. Tramway ne se positionne ni comme courtier matrimonial ni comme conseiller en vue d'une union.",
        title: "Exclusion de responsabilité",
      },
      {
        id: uuidv4(),
        text: "Pour protéger ses membres, Tramway a mis en place diverses mesures de sécurité, notamment une page d'aide et de sécurité, un email de contact (onpapote@tramway.life), et une liste des membres indésirables appelée 'Québlolist'. Les membres peuvent signaler des comportements ou contenus inappropriés. Tramway modère les pseudonymes, descriptions et photos des membres, le courrier de manière générale",
        title: "Mesures de protection",
      },
    ],
  },

  {
    id: uuidv4(),
    title: "Article 18 : Données des membres",
    children: [
      {
        id: uuidv4(),
        text: `Les données des membres seront supprimées sur demande du membre ou à l'expiration des délais légaux après la résiliation de son compte.`,
        title: "Suppression des données",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 19 : Messagerie des membres résiliés ou désinscrits",
    children: [
      {
        id: uuidv4(),
        text: `Toutes les informations et messages des membres désinscrits ou résiliés sont définitivement supprimés, rendant les profils inaccessibles.`,
        title: "Suppression des informations",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 20 : Confidentialité",
    children: [
      {
        id: uuidv4(),
        title: "Exercice des droits d'accès et de rectification",
        text: "Pour exercer ses droits d'accès et de rectification sur ses données, le membre peut se connecter à son compte profil avec son identifiant et son code MFA si celui-ci est actif.",
      },
      {
        id: uuidv4(),
        title: "Protection des données personnelles",
        text: "Les données personnelles du membre, telles que son nom, prénom, adresse postale ou électronique, demeurent confidentielles et sécurisées. Elles ne seront ni divulguées ni vendues à des tiers, et seront utilisées uniquement pour le traitement des commandes et l'établissement des factures.",
      },
      {
        id: uuidv4(),
        title: "Gestion des données en cas de demande de suppression",
        text: "En cas de demande de suppression de ses données par le membre, Tramway s'engage à supprimer les données personnelles dans les meilleurs délais, sauf si leur conservation est nécessaire pour des obligations légales ou réglementaires. Le membre peut effectuer une telle demande en contactant le service clientèle à l'adresse e-mail indiquée sur le site.",
      },
      {
        id: uuidv4(),
        title: "Procédures de sécurisation des données",
        text: "Tramway met en place des mesures de sécurité appropriées pour protéger les données personnelles contre tout accès non autorisé, toute divulgation, toute altération ou toute destruction non autorisée. Ces mesures comprennent notamment la sécurisation des serveurs, l'utilisation de protocoles de chiffrement et la restriction de l'accès aux données aux seules personnes autorisées. Pour sécuriser davantage les échanges courriels, le membre peut contacter le Délégué à la Protection des Données (DPO) à l'adresse e-mail suivante : dpo@tramway.life. De plus, deux clés PGP sont téléchargeables depuis la page des mentions légales pour crypter les communications par e-mail en toute sécurité.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 21 : Accessibilité aux services",
    children: [
      {
        id: uuidv4(),
        title: "Disponibilité du site",
        text: "L’utilisateur reconnaît avoir été informé que le présent site est accessible 24 heures sur 24 et 7 jours sur 7, à l’exception des cas de force majeure, difficultés informatiques ou difficultés techniques.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 22 : Obligations du membre",
    children: [
      {
        id: uuidv4(),
        title: "Conformité des renseignements personnels",
        text: "Les renseignements personnels fournis par les membres doivent être exacts et conformes à la réalité. Toute fausse déclaration sera sanctionnée par une radiation du membre.",
      },
      {
        id: uuidv4(),
        title: "Signalement de profils problématiques",
        text: "Le membre est tenu de signaler à Tramway tout profil qui semble porter atteinte aux lois, règlements ou bonnes mœurs. Il doit également respecter les consignes et instructions fournies par Tramway lors de sa navigation sur le site.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 23 : Interdictions concernant le membre",
    children: [
      {
        id: uuidv4(),
        title: "Usages prohibés du site",
        text: "Il est interdit d'utiliser le site à des fins commerciales, de tenir des propos injurieux, insultants ou discriminatoires, ou de faire des messages publicitaires ou répétitifs.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 24 : Photos des membres et informations personnelles",
    children: [
      {
        id: uuidv4(),
        title: "Conditions relatives aux photos des membres",
        text: "Les photos des membres doivent respecter les bonnes mœurs et ne pas avoir un caractère sexuel. Il est interdit de proposer des photos indécentes ou représentant des tiers sans leur consentement.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 25 : Interdictions concernant les photos du membre",
    children: [
      {
        id: uuidv4(),
        title: "Restrictions sur les contenus des photos",
        text: "Il est interdit de proposer des photos dénudées, représentant des enfants ou des éléments inappropriés. Les photos sont vérifiées avant publication et peuvent être refusées si elles ne respectent pas ces critères.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 26 : Acceptation de la publication",
    children: [
      {
        id: uuidv4(),
        title: "Autorisation de publication",
        text: "En proposant des photos, une annonce ou un témoignage à Tramway, le membre autorise leur publication sur le site. Il renonce ainsi à toute réclamation pour atteinte à son droit à l'image ou à sa vie privée.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 27 : Annonces des membres",
    children: [
      {
        id: uuidv4(),
        title: "Contenu des annonces",
        text: "Les annonces des membres doivent être rédigées en français et ne contenir aucun contenu religieux, politique, raciste ou injurieux. Il est également interdit de divulguer des informations personnelles ou de faire des propositions frauduleuses.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 28 : Réglementation des pseudos",
    children: [
      {
        id: uuidv4(),
        title: "Gestion des pseudonymes",
        text: "Les pseudonymes peuvent être modifiés par Tramway si nécessaire.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 29 : Gestion des messages",
    children: [
      {
        id: uuidv4(),
        title: "Suppression des messages",
        text: "Les messages lus datant d'au moins un an seront automatiquement supprimés. Les membres doivent donc enregistrer régulièrement leurs messages s'ils souhaitent les conserver.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 30 : Acceptation des risques de l'internet",
    children: [
      {
        id: uuidv4(),
        title: "Responsabilité du membre",
        text: "Le membre reconnaît les risques liés à l'utilisation d'internet et dégage Tramway de toute responsabilité en cas de perte de données ou de problèmes liés à son espace personnel sur le site.",
      },
    ],
  },

  {
    id: uuidv4(),
    title: "Article 31 : Modification des conditions générales d'utilisation et de vente",
    children: [
      {
        id: uuidv4(),
        title: "Droit de modification",
        text: "Tramway se réserve le droit de modifier tout ou partie des présentes conditions générales d'utilisation et de vente à tout moment. Il appartient au membre de se référer régulièrement à la dernière version des CGUV disponible sur ce site. Tout usage après modifications des CGUV vaut acceptation par les membres des nouvelles conditions générales d'utilisation.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 32 : Propriété intellectuelle",
    children: [
      {
        id: uuidv4(),
        title: "Protection des éléments du site",
        text: "Les marques, les logos, les graphismes, les animations, et plus généralement tout le contenu du site Tramway sont la propriété de Tramway et sont protégés par les lois françaises et internationales relatives à la propriété intellectuelle. Ces éléments ne peuvent donc être reproduits, utilisés ou représentés sans l'autorisation expresse écrite et préalable de Tramway, qui peut la subordonner à une contrepartie financière. Toute reproduction totale ou partielle du contenu du site est strictement interdite et est susceptible de constituer un délit de contrefaçon.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 33 : Loi applicable – litige",
    children: [
      {
        id: uuidv4(),
        title: "Réglementation et juridiction",
        text: "Les présentes conditions générales sont régies, interprétées et appliquées conformément au droit français, la langue d'interprétation étant la langue française en cas de contestation sur la signification d'un terme ou d'une disposition des présentes conditions d'utilisation et de vente. Par conséquent, dans l'hypothèse où elles seraient traduites en une ou plusieurs langues, seul le texte français fera foi en cas de litige. Sous réserve des dispositions d'ordre public applicables en matière de compétence juridictionnelle, les Tribunaux de Lille, France, seront seuls compétents pour connaître de tout litige relatif aux présentes, y compris, sans que cette énumération soit limitative, leur validité, leur interprétation, leur exécution et/ou leur résiliation et ses conséquences.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 34 : Acceptation des présentes conditions générales d'utilisation et de vente",
    children: [
      {
        id: uuidv4(),
        title: "Consentement et renonciation",
        text: "Le membre déclare avoir pris connaissance et accepté de manière inconditionnelle les présentes CGUV au jour de son inscription ou de son abonnement. En acceptant les Conditions Générales d'Utilisation, le membre renonce à toute réclamation ou action en justice relative à des dommages directs ou indirects relevant d'une non application ou d'une mauvaise application des conditions générales d'utilisation.",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Article 35 : A propos de Tramway",
    children: [
      {
        id: uuidv4(),
        title: "Tramway • courant alternatif ® est une marque française",
        text: "Tramway est une marque française déposée à l’Institut National de la Propriété Intellectuelle (INPI) sous le N° 5027214. Toutes les marques citées, noms de produits ou de services, ainsi que les logos, sont la propriété de leurs sociétés respectives.",
      },
      {
        id: uuidv4(),
        title: "Informations sur l'entreprise",
        text: "Bluecornflakes, créateur de Tramway, est une entreprise française enregistrée sous le numéro SIRET 520 820 549 000 43, et régie par l'Article A123-96 du Code de Commerce. ",
      },
      {
        id: uuidv4(),
        title: "Contact et Protection des données",
        text: "Vous pouvez nous contacter via notre page Contact ou directement par email à onpapote@tramway.life. Tramway s'engage à protéger la confidentialité des données personnelles de ses utilisateurs, conformément aux lois françaises sur la protection des données.",
      },
      {
        id: uuidv4(),
        title: "Utilisation des marques",
        text: "Tous les designs et illustrations sont protégés par des droits d'auteur. Tout le contenu du site Web est également protégé par des droits d'auteur et tous les droits sont réservés. Toute utilisation des marques, logos, graphismes ou autres éléments de propriété intellectuelle de Tramway doit être expressément autorisée par écrit par Bluecornflakes. Toute violation de cette condition peut entraîner des poursuites judiciaires.",
      },
    ],
  },
]);

export { CGV };
