import { v4 as uuidv4 } from "uuid";

const RGPD = Object.freeze([
  {
    id: uuidv4(),
    title: "1 - Champ d’application de la présente politique de protection des données à caractère personnel",
    children: [
      {
        id: uuidv4(),
        title: "Applicabilité du RGPD",
        text: "Le Règlement (UE) n°2016/679 du Parlement Européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement de données à caractère personnel et à la libre circulation de ces données (« RGPD ») applicable à partir du 25 mai 2018 s'applique à toutes les entreprises qui collectent, conservent ou traitent des données à caractère personnel.",
      },
      {
        id: uuidv4(),
        title: "Définition des données à caractère personnel",
        text: "La notion de données à caractère personnel (les « Données à caractère personnel ») désigne toute information se rapportant à une personne physique identifiée ou identifiable. Est réputée être une « personne physique identifiable » une personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.",
      },
      {
        id: uuidv4(),
        title: "Traitements des données personnelles par Tramway",
        text: "Tramway traite vos données à caractère personnel de manière transparente et conforme à la réglementation en vigueur dans les trois situations suivantes : \n\n1. Lorsque vous contactez Tramway par email ;\n2. Lorsque vous visitez le site www.tramway.life (le « Site ») ;\n3. Lors de la mise à disposition des informations personnelles relatives aux profils des membres sur son site www.tramway.life (le « Site »), dans le respect des conditions légales applicables.",
      },
    ],
  },

  {
    id: uuidv4(),
    title:
      "2 - Traitement de Vos Données à caractère personnel en cas d’envoi d’un e-mail à Tramway via le courriel ou le formulaire de contact de Tramway",
    children: [
      {
        id: uuidv4(),
        text: "Si Vous contactez Tramway par email, des Données à caractère personnel Vous concernant pourront nous être transmises et traitées à cette occasion : Adresse électronique (nécessairement) ; Et toutes données à caractère personnel que Vous voudrez bien transmettre à Tramway à l’occasion de vos messages. Ces Données à caractère personnel servent à traiter Votre message et y répondre s’il appelle une réponse, ainsi qu’à améliorer les services de Tramway. Elles sont collectées et traitées sur la base de l’intérêt légitime de Tramway à gérer ses relations publiques et à vous répondre, conformément à l’article 6.1.f) du RGPD.",
        title: "COURRIEL : Finalité, base légale du traitement et Données à caractère personnel traitées",
      },
      {
        id: uuidv4(),
        text: "Tramway conserve ces Données à caractère personnel pour toute la durée nécessaire au traitement de Votre message et de ses suites éventuelles, puis pour une durée complémentaire de 5 ans à titre de preuve conformément à la durée de la prescription applicable. Passés ces délais, les Données à caractère personnel sont supprimées.",
        title: "COURRIEL : Durée du traitement",
      },
      {
        id: uuidv4(),
        text: "Tramway n’a pas vocation à transférer les données ainsi collectées en dehors de l’Union européenne. Notre partenaire Mailo stocke et traite vos données personnelles et vos contenus sur des serveurs situés en France.",
        title: "COURRIEL : Transfert et destinataires des Données à caractère personnel",
      },
      {
        id: uuidv4(),
        text: "Les Données à caractère personnel peuvent être communiquées aux préposés de Tramway qui en ont besoin dans le cadre de l’exécution de leur mission, aux services chargés du contrôle de Tramway, ainsi qu’aux organismes, auxiliaires de justice et officiers ministériels lorsque la société Tramway y est légalement obligée ou lorsque cette divulgation est nécessaire pour faire exécuter les conditions générales ou autres accords, protéger Vos droits, Vos biens ou ceux de Tramway.",
        title: "COURRIEL : Destinataires des Données à caractère personnel",
      },
      {
        id: uuidv4(),
        text: "En application du RGPD, Vous disposez d'un d'accès, de rectification, d'effacement, droit à la limitation du traitement, à la portabilité des données, à s'opposer au traitement de Vos données et d'un droit de décider du sort de Vos données après votre mort.",
        title: "COURRIEL : Vos droits sur Vos Données à caractère personnel",
      },
      {
        id: uuidv4(),
        text: "Vous pouvez également introduire une plainte auprès d'une autorité de contrôle, à savoir la CNIL en France, si Vous estimez que le traitement de Vos Données à caractère personnel n'est pas conforme à la règlementation en vigueur, à l'adresse suivante : CNIL - Service des plaintes 3, place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
        link: "tel:0033153732222",
        title: "COURRIEL : La CNIL en France",
      },
    ],
  },
  {
    id: uuidv4(),
    title:
      "3 - Traitement de Vos Données à caractère personnel dans le cadre de la mise à disposition aux membres des profils existants",
    children: [
      {
        id: uuidv4(),
        title: "PROFILS : Finalité, base légale du traitement et Données à caractère personnel traitées",
        text: "Les personnes ayant communiqué à Tramway des informations afférentes à leur inscription (et notamment des Données à caractère personnel), peuvent ainsi raisonnablement s’attendre, au moment de la collecte de telles données, à ce que celles-ci fassent l’objet d’un traitement ultérieur par Tramway.\n\nDans le cadre de la diffusion des informations, Tramway est ainsi nécessairement amenée à traiter les Données à caractère personnel qui y sont inclues :\n- Nom\n- Prénom\n- Pseudo\n- Civilité\n- Date et lieu de naissance\n- Nationalité\n- Régime matrimonial\n- Adresse postale\n- Emploi\n- CENTRES D'INTÉRÊT\n- ANIMAUX DE COMPAGNIE\n- MODE D'ALIMENTATION\n- Signe astrologique\n- Langue\n- Description physique\n\nLes informations qui concernent le handicap, l'orientation sexuelle, l'origine éthnique, la religion, la santé sont des données dites sensibles. Sous la responsabilité du Membre, elles sont visibles sur le site www.tramway.life par les autres Membres du site. Le Membre accepte de façon expresse le traitement de ces données par Tramway. Cette liste n'est pas nécessairement représentative des données et est volontairement étoffée pour vous sensibiliser explicitement. Néanmoins, Tramway préfère une approche moins intrusive et essaiera toujours les options plus anonymisantes.",
      },
      {
        id: uuidv4(),
        title: "PROFILS : Cadre légal",
        text: "Tramway agit dans le cadre légal et permet à tous les membres d’avoir un accès à de telles informations gratuitement via son site internet. Les informations personnelles lui sont fournies par les sources suivantes : les membres eux-mêmes.",
      },
      {
        id: uuidv4(),
        title: "PROFILS : Objectifs",
        text: "Ce traitement est ainsi fondé sur l’article 6.1.f) du RGPD, étant en effet nécessaire aux fins des intérêts légitimes de Tramway de mettre à disposition du public de manière légale, les informations des membres. Ce traitement ne poursuit pas d’autres objectifs.",
      },
      {
        id: uuidv4(),
        title: "PROFILS : Durée du traitement",
        text: "Les données à caractère personnel sont conservées pendant la durée d'inscription de la personne concernée sur le site web Tramway, augmentée de la durée des prescriptions légales. Passés ces délais, les Données à caractère personnel sont supprimées.",
      },
      {
        id: uuidv4(),
        title: "PROFILS : Transfert et destinataires des Données à caractère personnel",
        text: "Tramway n’a pas vocation à transférer les données ainsi collectées en dehors de l’Union européenne. Vos données de profil sont stocké sur la région européenne suivante : West EU (Ireland) (eu-west-1)",
      },
      {
        id: uuidv4(),
        title: "PROFILS : Destinataires des Données à caractère personnel",
        text: "Les Données à caractère personnel peuvent être communiquées aux préposés de Tramway qui en ont besoin dans le cadre de l’exécution de leur mission, aux services chargés du contrôle de Tramway, ainsi qu’aux organismes, auxiliaires de justice et officiers ministériels lorsque la société Tramway y est légalement obligée ou lorsque cette divulgation est nécessaire pour faire exécuter les conditions générales ou autres accords, protéger Vos droits, Vos biens ou ceux de Tramway.",
      },
      {
        id: uuidv4(),
        text: "PROFILS : En application du RGPD, Vous disposez d'un d'accès, de rectification, d'effacement, droit à la limitation du traitement, à la portabilité des données, à s'opposer au traitement de Vos données et d'un droit de décider du sort de Vos données après votre mort.",
        title: "Vos droits sur Vos Données à caractère personnel",
      },
      {
        id: uuidv4(),
        text: "PROFILS : Vous pouvez également introduire une plainte auprès d'une autorité de contrôle, à savoir la CNIL en France, si Vous estimez que le traitement de Vos Données à caractère personnel n'est pas conforme à la règlementation en vigueur, à l'adresse suivante : CNIL - Service des plaintes 3, place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
        link: "tel:0033153732222",
        title: "La CNIL en France",
      },
    ],
  },

  {
    id: uuidv4(),
    title: "4 - Traitement de Vos Données à caractère personnel dans le cadre de votre visite sur le Site",
    children: [
      {
        id: uuidv4(),
        text: "Lors de la visite de notre site Internet, le navigateur utilisé par le visiteur envoie automatiquement des données au serveur de notre site web et les stocke dans un fichier journal pour une période limitée. Les données suivantes sont stockées sans intervention du visiteur jusqu'à leur suppression automatique : l'adresse IP de l'appareil du visiteur, la date et l'heure d'accès, le nom et l'URL de la page visitée, le site web de provenance (URL de référence), le navigateur et le système d'exploitation de l'appareil utilisé ainsi que le nom du fournisseur d'accès Internet.",
        title: "VISITE : Collecte et Stockage des Données de Navigation",
      },
      {
        id: uuidv4(),
        text: "Établir rapidement une connexion avec le site web de l'entreprise, permettre une utilisation conviviale de ce site, reconnaître et garantir la sécurité et la stabilité des systèmes, et faciliter ainsi que d'améliorer l'administration de ce site.",
        title: "VISITE : Finalités du Traitement des Données Personnelles",
      },
      {
        id: uuidv4(),
        text: "Dans le cadre de votre visite sur le site www.tramway.life et conformément à la réglementation européenne en vigueur, Tramway conserve les données listées ci-dessus pendant une durée de 14 mois, assortie, le cas échéant, d'autres délais légaux.",
        title: "VISITE : Durée de Conservation des Données",
      },
      {
        id: uuidv4(),
        text: "Les données personnelles sont transmises à des tiers si : la personne concernée a expressément donné son consentement conformément à l'article 6, paragraphe 1, phrase 1, lit. a du RGPD ; la divulgation est nécessaire pour faire valoir, exercer ou défendre des droits en justice conformément à l'article 6, paragraphe 1, phrase 1, lit. f du RGPD, et qu'il n'existe aucune raison de supposer que la personne concernée a un intérêt légitime supérieur à ce que ses données ne soient pas divulguées ; il existe une obligation légale de transmission des données conformément à l'article 6, paragraphe 1, phrase 1, lit. c du RGPD ; ceci est nécessaire pour l'exécution d'une relation contractuelle avec la personne concernée conformément à l'article 6, paragraphe 1, phrase 1, lit. b du RGPD. Dans les autres cas, les données personnelles ne seront pas transmises à des tiers.",
        title: "VISITE : Transfert et Destinataires des Données Personnelles",
      },
      {
        id: uuidv4(),
        text: "Les Données à caractère personnel peuvent être communiquées aux préposés de Tramway qui en ont besoin dans le cadre de l'exécution de leur mission, aux services chargés du contrôle de Tramway, ainsi qu'aux organismes, auxiliaires de justice et officiers ministériels lorsque l'entreprise Bluecornflakes pour Tramway y est légalement obligée ou lorsque cette divulgation est nécessaire pour faire exécuter les conditions générales ou autres accords, protéger Vos droits, Vos biens ou ceux de Tramway.",
        title: "VISITE : Destinataires des Données à caractère personnel",
      },

      {
        id: uuidv4(),
        text: "En application du RGPD, Vous disposez d'un d'accès, de rectification, d'effacement, droit à la limitation du traitement, à la portabilité des données, à s'opposer au traitement de Vos données et d'un droit de décider du sort de Vos données après votre mort.",
        title: "VISITE : Vos droits sur Vos Données à caractère personnel",
      },
      {
        id: uuidv4(),
        text: "Vous pouvez également introduire une plainte auprès d'une autorité de contrôle, à savoir la CNIL en France, si Vous estimez que le traitement de Vos Données à caractère personnel n'est pas conforme à la règlementation en vigueur, à l'adresse suivante : CNIL - Service des plaintes 3, place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
        link: "tel:0033153732222",
        title: "VISITE : La CNIL en France",
      },
      {
        id: uuidv4(),
        text: "Notre site web utilise des \"cookies\", qui sont des paquets de données échangés entre le serveur de ce site web et le navigateur du visiteur. Ils sont stockés sur les appareils utilisés (PC, ordinateur portable, tablette, smartphone, etc.) lors de la visite de ce site web. À cet égard, les cookies ne peuvent causer aucun dommage aux appareils utilisés. En particulier, ils ne contiennent pas de virus ou d'autres logiciels malveillants. Les informations stockées dans les cookies résultent de l'appareil spécifiquement utilisé dans chaque cas. En aucun cas, l'entreprise ne peut obtenir la connaissance directe de l'identité du visiteur de ce site web.\n\nLes cookies sont utilisés pour rendre l'utilisation du service en ligne de l'entreprise plus pratique. Par exemple, les cookies de session peuvent être utilisés pour déterminer si le visiteur a déjà visité des pages individuelles de ce site web. Ces cookies de session sont automatiquement supprimés après avoir quitté ce site web.\n\nLes cookies temporaires sont utilisés pour améliorer la convivialité du site. Ils sont stockés temporairement sur l'appareil du visiteur. Lors d'une nouvelle visite du site web, il est automatiquement reconnu que le visiteur a déjà visité la page à un moment antérieur et que les entrées et les réglages ont été effectués de manière à ne pas devoir les répéter.\n\nLes cookies sont également utilisés pour analyser les visites sur ce site web à des fins statistiques et pour améliorer le service. Ces cookies permettent de reconnaître automatiquement que le site web a déjà été visité auparavant par le visiteur lorsqu'il le visite à nouveau. Les cookies sont automatiquement supprimés après une période déterminée. Les données traitées par les cookies sont justifiées aux fins susmentionnées pour sauvegarder les intérêts légitimes de l'entreprise conformément à l'article 6, paragraphe 1, phrase 1, lit. f du RGPD.",
        title: "VISITE : Utilisation des Cookies et Finalités",
      },
      {
        id: uuidv4(),
        text: "Google Analytics : Ce site web utilise Google Analytics, un service d'analyse du web. Il est géré par Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Google Analytics utilise ce qu'on appelle des cookies. Il s'agit de fichiers texte qui sont stockés sur votre ordinateur et qui permettent d'analyser l'utilisation du site web par vous. Les informations générées par le cookie concernant votre utilisation de ce site web sont généralement transmises à un serveur de Google aux États-Unis et y sont stockées. Les cookies de Google Analytics sont stockés sur la base de l'art. 6 (1) (f) DSGVO. L'exploitant du site web a un intérêt légitime à analyser le comportement des utilisateurs afin d'optimiser son site web et sa publicité.",
        title: "VISITE : Services d'analyse de sites web, suivi",
      },
      {
        id: uuidv4(),
        text: "Nous avons activé la fonction d'anonymisation de l'IP sur ce site web. Votre adresse IP sera raccourcie par Google au sein de l'Union européenne ou d'autres parties à l'accord sur l'Espace économique européen avant d'être transmise aux États-Unis. Ce n'est que dans des cas exceptionnels que l'adresse IP complète est envoyée à un serveur de Google aux États-Unis et y est raccourcie. Google utilisera cette information pour le compte de l'exploitant de ce site Web afin d'évaluer votre utilisation du site, de compiler des rapports sur l'activité du site et de fournir d'autres services relatifs à l'activité du site et à l'utilisation d'Internet pour l'exploitant du site. L'adresse IP transmise par votre navigateur dans le cadre de Google Analytics ne sera pas fusionnée avec d'autres données détenues par Google.",
        title: "VISITE : Anonymisation de l'IP ",
      },
      {
        id: uuidv4(),
        text: "Vous pouvez empêcher l'enregistrement de ces cookies en sélectionnant les paramètres appropriés de votre navigateur. Le transfert de données vers les États- Unis, qui ne peut être exclu lors de l'utilisation des services Google, est basé sur les clauses contractuelles types de la Commission européenne.",
        title: "VISITE : Plugin de navigateur",
      },
      {
        id: uuidv4(),
        text: "Policies.google les détails ici - Lien externe.",
        link: "https://policies.google.com/privacy/frameworks",
        title: "VISITE : privacy.google",
      },

      {
        id: uuidv4(),
        text: "Tramway utilise les services de CloudFlare afin de protéger son site internet des attaques malveillantes. CloudFlare est une solution d'optimisation des performances qui fonctionne en reverse proxy, c'est-à-dire qu'elle agit comme un intermédiaire entre le site web et les visiteurs. Le siège social de CloudFlare, Inc. est situé 101 Townsend St. San Francisco, CA 9410. Pour plus d'informations concernant les modalités de gestion de vos données par CloudFlare, veuillez consulter leur politique de confidentialité :",
        link: "https://www.cloudflare.com/privacypolicy",
        title: "VISITE : Cloudflare",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "5 - Sécurité des Données à caractère personnel",
    children: [
      {
        id: uuidv4(),
        text: "Au regard de l’état de l’art, Tramway a pris des mesures de protection physiques, électroniques et organisationnelles et techniques pour prévenir toute perte, mauvaise utilisation, accès ou diffusion non autorisé, altération ou destruction éventuelle de Vos Données à caractère personnel.",
        title: "Mesures de Sécurité des Données Personnelles",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "6 - Liens vers des sites web non contrôlés par Tramway",
    children: [
      {
        id: uuidv4(),
        text: "Le Site peut proposer des liens vers des sites Internet de tiers susceptibles de vous intéresser.",
        title: "Liens vers des Sites Tiers",
      },
      {
        id: uuidv4(),
        text: "Tramway n'exerce toutefois aucun contrôle sur le contenu de sites de tiers ou sur les pratiques de ces tiers en matière de protection des Données à caractère personnel qu'ils pourraient recueillir. En conséquence, Tramway décline toute responsabilité concernant le traitement par ces tiers de Vos Données à caractère personnel sur lesdits sites.",
        title: "Responsabilité et Traitement des Données par les Sites Tiers",
      },
      {
        id: uuidv4(),
        text: "Il est de votre responsabilité de vous renseigner sur les politiques de protection des Données à caractère personnel de ces tiers.",
        title: "Votre Responsabilité envers les Politiques de Protection des Données des Tiers",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "7 - Contact",
    children: [
      {
        id: uuidv4(),
        text: "Pour toute question concernant la présente Politique, ou pour demande d’exercice de droits sur Vos Données à caractère personnel, Vous pouvez nous contacter via la page Contact ou en suivant ce lien.",
        title: "onpapote@tramway.life",
        link: "mailto:onpapote@tramway.life",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "8 - Évolution de la Politique de protection des données à caractère personnel",
    children: [
      {
        id: uuidv4(),
        text: "La présente Politique peut être modifiée ou aménagée à tout moment notamment en cas d’évolution légale, jurisprudentielle, des décisions et recommandations de la CNIL ou des usages.",
        title: "Modifications de la Politique de Confidentialité",
      },
      {
        id: uuidv4(),
        text: "Toute nouvelle version de la présente Politique sera portée à Votre connaissance par tout moyen choisi par Tramway en ce compris la voie électronique (diffusion par courrier électronique ou en ligne sur le site par exemple).",
        title: "Communication des Modifications",
      },
      {
        id: uuidv4(),
        text: "Nous Vous invitons par conséquent à prendre régulièrement connaissance des mises à jour.",
        title: "Informations sur les Mises à Jour",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "9 - Liens externes vers les politiques de nos partenaires",
    children: [
      {
        id: uuidv4(),
        text: "Cloudflare propose une plateforme unifiée de services de connectivité cloud, de sécurité et de développement pour les entreprises.",
        title: "Cloudflare",
        link: "https://www.cloudflare.com/fr-fr/trust-hub/gdpr/",
        flag: ": doc en français 🇫🇷",
        lang: "français",
      },

      {
        id: uuidv4(),
        text: "Mailo est une plateforme française qui propose des services de webmail, de cloud, d'agenda et d'autres services dans le respect de vos données et de la personnalisation.",
        title: "Mailo",
        link: "https://www.mailo.com/mailo/fr/regles-de-confidentialite.php",
        flag: ": doc en français 🇫🇷",
        lang: "français",
      },
      {
        id: uuidv4(),
        text: "IPQS est une solution qui offre une protection en temps réel contre la fraude, les cybermenaces et les données non valides.",
        title: "IpqualityScore",
        link: "https://www.ipqualityscore.com/privacy-policy",
        flag: ": doc en anglais 🇬🇧",
        lang: "anglais",
      },
      {
        id: uuidv4(),
        text: "Sightengine offre une suite de produits permettant de détecter, d'expurger et d'anonymiser les contenus indésirables dans les images, les vidéos et les textes.",
        title: "SightEngine",
        link: "https://sightengine.com/faq/gdpr-content-moderation",
        flag: ": doc en anglais 🇬🇧",
        lang: "anglais",
      },
      {
        id: uuidv4(),
        text: "EmailJS est un service qui vous permet d'envoyer des courriels à partir de votre code sans code côté serveur.",
        title: "Email-JS",
        link: "https://www.emailjs.com/legal/data-protection-agreement/",
        flag: ": doc en anglais 🇬🇧",
        lang: "anglais",
      },
      {
        id: uuidv4(),
        text: "Services de traitement des paiements en ligne",
        title: "Stripe",
        link: "https://stripe.com/fr/privacy",
        flag: ": doc en français 🇫🇷",
        lang: "français",
      },
      {
        id: uuidv4(),
        text: "Supabase est un service d'arrière-plan qui fournit une base de données Postgres",
        title: "Supabase",
        link: "https://supabase.com/privacy",
        flag: ": doc en anglais 🇬🇧",
        lang: "anglais",
      },
    ],
  },
]);

export { RGPD };
