import { supabase } from "../supabaseClient";
import { useState, useCallback, useContext, useMemo, useRef } from "react";
import UserSession from "../contexts/UserSession";
import { useNavigate, useLocation } from "react-router-dom";
import clipboard from "clipboardy";
import { ToastQueue } from "@react-spectrum/toast";
import useVibration from "../hooks/useVibration";

export default function MFAHOC(WrappedComponent) {
  return function WithStateManagement() {
    const location = useLocation();
    const navigate = useNavigate();
    const { vibrate } = useVibration();
    const { currentLevelMFA, setCurrentLevelMFA, nextLevelMFA, setNextLevelMFA } = useContext(UserSession);

    const [qr, setQR] = useState();
    const [factorId, setFactorId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [validationState, setValidationState] = useState("");
    const [codeMFA, setCodeMFA] = useState("");
    const prevFactorIdRef = useRef();

    const currentUrl = useMemo(() => location.pathname, [location.pathname]);
    const isAuth = useMemo(
      () => currentLevelMFA === "aal2" && nextLevelMFA === "aal2",
      [currentLevelMFA, nextLevelMFA],
    );

    const getLastFactorId = useCallback(async () => {
      setValidationState("");
      setIsLoading(true);
      try {
        const { data: listFactorsData } = await supabase.auth.mfa.listFactors();
        const totpFactor = listFactorsData?.all[0];

        if (!totpFactor) {
          setNextLevelMFA("aal1");
          setValidationState({
            name: "Information de validation",
            message:
              "Impossible de récupérer ton dernier QR code. Réessaye, si le problème persiste, contacte-nous pour une assistance.",
          });
          return;
        }

        setNextLevelMFA(totpFactor?.status === "verified" && totpFactor?.factor_type === "totp" ? "aal2" : "aal1");
        prevFactorIdRef.current = totpFactor.id;
        setFactorId(totpFactor.id);
      } catch (error) {
        setNextLevelMFA("aal1");
        setValidationState({
          name: "Information de validation",
          message:
            "Impossible de récupérer ton dernier QR code. Réessaye, si le problème persiste, contacte-nous pour une assistance.",
        });
      } finally {
        setIsLoading(false);
      }
    }, [setNextLevelMFA]);

    const enRollMFA = useCallback(async () => {
      setIsLoading(true);
      setValidationState("");
      try {
        const { data, error } = await supabase.auth.mfa.enroll({ factorType: "totp", issuer: "Tramway" });

        if (error) {
          setValidationState({
            name: "Les QR codes sont épuisés",
            message: "Régénère le dernier QR code. Si le problème persiste, contacte-nous pour une assistance.",
          });
          return;
        }

        setFactorId(data.id);
        setQR(data.totp.qr_code);
      } catch (error) {
        setValidationState({
          name: "Information de validation",
          message:
            "Une erreur inattendue s'est produite lors de l'activation. Réessaye, s'il te plaît. Si le problème persiste, contacte-nous pour une assistance.",
        });
      } finally {
        setIsLoading(false);
      }
    }, []);

    const unenRollMFA = useCallback(async () => {
      setIsLoading(true);
      setValidationState("");
      try {
        const { error } = await supabase.auth.mfa.unenroll({ factorId: factorId ?? prevFactorIdRef.current });

        if (error) {
          setValidationState({
            name: "Information de validation",
            message: "Oops. Réessaye ! Si le problème persiste, contacte-nous pour une assistance.",
          });
        }
      } catch (error) {
        setValidationState({
          name: "Information de validation",
          message: "Oops. Réessaye ! Si le problème persiste, contacte-nous pour une assistance.",
        });
      } finally {
        setIsLoading(false);
      }
    }, [factorId]);

    const challengeAndVerifyMFA = useCallback(
      async (factorId, code) => {
        setIsLoading(true);
        try {
          const { error } = await supabase.auth.mfa.challengeAndVerify({ factorId, code });

          if (error) {
            setValidationState({
              name: "Code aléatoire invalide",
              message:
                "Le code aléatoire doit être composé de 6 chiffres. Utilise l'application d'authentification, par exemple FreeOTP+ pour Android. Merci de vérifier et de réessayer.",
            });
            return;
          }

          const { data, error: errorAssuranceLevel } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
          if (errorAssuranceLevel) {
            setValidationState({
              name: "Niveau d'Authentification",
              message:
                "Impossible d'établir le Niveau d'Authentification pour la session active : AAL1 ou AAL2. Réessaye, s'il te plaît. Si le problème persiste, contacte-nous pour une assistance.",
            });
            return;
          }

          setCurrentLevelMFA(data.currentLevel);
          setNextLevelMFA(data.nextLevel);
          setCodeMFA("");
          vibrate();
          ToastQueue.positive("MFA active", { timeout: 5000 });
          if (isAuth) navigate(currentUrl);
        } catch (error) {
          setValidationState({
            name: "Information de validation",
            message:
              "Une erreur inattendue s'est produite lors de la vérification du code. Réessaye, s'il te plaît. Si le problème persiste, contacte-nous pour une assistance.",
          });
        } finally {
          setIsLoading(false);
        }
      },
      [currentUrl, isAuth, navigate, setCurrentLevelMFA, setNextLevelMFA, vibrate],
    );

    const handleEnableMFA = async () => {
      setIsLoading(true);
      setValidationState("");

      const code = codeMFA;
      const isValidMFA = /^\d{6}$/.test(code);

      if (!isValidMFA) {
        setValidationState({
          name: "Code aléatoire invalide",
          message:
            "Le code aléatoire doit être composé de 6 chiffres. Utilise l'application d'authentification, par exemple FreeOTP+ pour Android. Merci de vérifier et de réessayer.",
        });
        setIsLoading(false);
        return;
      }

      if (!factorId) {
        await getLastFactorId();
        await challengeAndVerifyMFA(prevFactorIdRef.current, code);
      } else {
        await challengeAndVerifyMFA(factorId, code);
      }
    };

    const handleCodeMFAChange = (value) => {
      const sanitizedValue = value.replace(/\s+/g, "").slice(0, 6);
      setCodeMFA(sanitizedValue);
    };

    const getIdCopy = useCallback(() => {
      clipboard.write(factorId);
      ToastQueue.positive(factorId + " Copied!", { timeout: 5000 });
    }, [factorId]);

    const handleReset = useCallback(() => {
      setCodeMFA("");
      setIsLoading(false);
      setValidationState("");
    }, []);

    const restartMFAProcess = async () => {
      try {
        await getLastFactorId();
        await unenRollMFA();
        await enRollMFA();
      } catch (error) {
        setValidationState({
          name: "Information de validation",
          message:
            "Une erreur inattendue s'est produite. Réessaye, s'il te plaît. Si le problème persiste, contacte-nous pour une assistance.",
        });
        setIsLoading(false);
      }
    };

    return (
      <WrappedComponent
        qr={qr}
        codeMFA={codeMFA}
        factorId={factorId}
        isLoading={isLoading}
        validationState={validationState}
        getIdCopy={getIdCopy}
        enRollMFA={enRollMFA}
        setCodeMFA={setCodeMFA}
        getLastFactorId={getLastFactorId}
        unenRollMFA={unenRollMFA}
        handleReset={handleReset}
        handleEnableMFA={handleEnableMFA}
        restartMFAProcess={restartMFAProcess}
        handleCodeMFAChange={handleCodeMFAChange}
      />
    );
  };
}
