import { v4 as uuidv4 } from "uuid";

const glossaire = Object.freeze([
  {
    id: uuidv4(),
    title: "Accueil",
    text: "Sur la page d'accueil, tu trouveras le tableau de bord avec 3 onglets : les membres prioritaires s'ils sont connectés, les visites et les favoris. Il y a aussi un petit moteur de recherche de membres, un bouton de vue géographique en mode carte, un bouton d'ajustement des paramètres de recherche personnalisés, et la liste des membres que tu as bloqués.",
  },
  {
    id: uuidv4(),
    title: "Membres",
    text: "Cet onglet te permet de découvrir tous les membres du Tramway. Ceux qui sont en ligne apparaissent en priorité.",
  },
  {
    id: uuidv4(),
    title: "Recherche",
    text: "Tu peux effectuer une recherche soit via un champ de recherche au clavier, soit via un bouton qui te permet d'ajuster et d'enregistrer des paramètres de recherche correspondant aux critères de ton choix (sexe, âge, localisation géographique, statut, pseudonyme, photo, annonce, etc.).",
  },
  {
    id: uuidv4(),
    title: "Profil",
    text: "En cliquant sur le bouton « Profil », ta page personnelle s'affiche. Tu peux y ajouter des photos, rédiger une annonce, remplir ta description. Il t'est possible de modifier ces éléments quand tu le désires. Attention, ton annonce et tes photos doivent respecter les CGU.",
  },
  {
    id: uuidv4(),
    title: "Compte",
    text: "Très simple et rapide, tu y trouveras 5 boutons : modifier mon mail, modifier mon pseudo, activer la double authentification, clôturer mon compte, télécharger mes données. Sans détour et facile à comprendre.",
  },
  {
    id: uuidv4(),
    title: "En ligne",
    text: "Cliquer sur ce bouton affiche les membres connectés à Tramway en même temps que toi.",
  },
  {
    id: uuidv4(),
    title: "Favoris",
    text: "Tu y trouveras la liste des personnes ajoutées à ta liste de favoris. Ce sont des membres qui ont attiré ton attention, de potentielles amitiés. Cette liste n’est pas visible par les autres membres.",
  },
  {
    id: uuidv4(),
    title: "Courrier",
    text: "Ce bouton désigne ton interface personnelle de messagerie interne. Tu as la possibilité d’y écrire, consulter, recevoir et répondre.",
  },
  {
    id: uuidv4(),
    title: "Visites",
    text: "Tu y verras les mini-profils des membres qui t'ont rendu visite.",
  },
  {
    id: uuidv4(),
    title: "Flash",
    text: "Affiche les mini-profils des membres qui ont flashé sur toi.",
  },
  {
    id: uuidv4(),
    title: "Sérénité",
    text: "L’alerte aux modérateurs est possible à tout moment en cliquant sur le menu dans le courrier, les profils et les mini-profils. Merci de bien vouloir justifier toute alerte. La liste des membres indésirables est appelée Québloliste. En ajoutant un membre à cette liste, son profil devient invisible pour toi, tout comme tu deviens invisible pour lui. Tu peux gérer ta liste directement depuis l'accueil, et il est possible de débloquer un membre directement dans cette liste.",
  },
  {
    id: uuidv4(),
    title: "Cercles",
    text: "Rejoindre des cercles t'aide à trouver des membres Tramway qui partagent les mêmes intérêts.",
  },
]);

export { glossaire };
