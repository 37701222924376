import { ToastQueue } from "@react-spectrum/toast";

const tramwayUrl = new URL("https://tramway.life");

const shareData = {
  title: "Tramway • courant alternatif",
  text: "Tramway ❤️ Acteur de la rencontre bio, écolo et accessible !",
  url: tramwayUrl,
};

const useShareApp = () => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(tramwayUrl.href);
    ToastQueue.positive("Lien copié !", { timeout: 3000 });
  };

  const handleShare = () => {
    if (!navigator.canShare || !navigator.share || !navigator.canShare(shareData)) {
      copyToClipboard();
    } else {
      navigator.share(shareData);
    }
  };

  return { handleShare };
};

export default useShareApp;
