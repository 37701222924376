import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";
import useLazyLoad from "../hooks/useLazyLoad";

const useLazyLoadWithLayout = (component) => {
  const LazyComponent = useLazyLoad(component);

  return (
    <Layout>
      <LazyComponent />
    </Layout>
  );
};

useLazyLoadWithLayout.propTypes = {
  importFunction: PropTypes.node.isRequired,
};

export default useLazyLoadWithLayout;
