import { v4 as uuidv4 } from "uuid";

const CGU = Object.freeze([
  {
    id: uuidv4(),
    title: "Conditions Générales d'Utilisation",
    children: [
      {
        id: uuidv4(),
        text: "Les présentes Conditions d’Utilisation des Services (ci-après « Conditions d'Utilisation ») sont conclues entre l'exploitant des services Tramway, (ci-après « Tramway »), et toute personne ayant la qualité de membre inscrit ou abonné (désigné par vous, tu, vos, tes, votre, ta). Tramway est est un site de rencontre dédié aux échanges entre personnes francophones de Belgique, France, Suisse et Canada qu'elles soient en situation de handicap ou non. Tramway n'exigera en aucun cas d'un Membre qu'il indique s'il est en situation de handicap. ",
        title: "Introduction",
      },
      {
        id: uuidv4(),
        text: "Les relations entre Tramway et ses utilisateurs identifiés (les utilisateurs identifiés seront ci-après désignés « les membres ») ou non sont régies par les présentes Conditions d'Utilisation. Tramway assure un service de qualité en privilégiant la sécurité et la satisfaction de ses utilisateurs.",
        title: "Relations contractuelles",
      },
      {
        id: uuidv4(),
        text: "Les conditions générales de vente seront en outre applicables aux membres. Elles régissent les transactions effectuées pour les services payants proposés par Tramway.",
        title: "Conditions de vente",
      },
      {
        id: uuidv4(),
        text: "L'inscription sur le site tramway.life n'est possible qu'aux personnes déclarant être majeures et capables de contracter, et après avoir pris connaissance des Conditions d'Utilisation et des conditions générales de vente de Tramway, et avoir pris l'engagement de respecter ces conditions. Les mineurs ne sont pas autorisés à utiliser les services Tramway.",
        title: "Inscription et âge requis",
      },
      {
        id: uuidv4(),
        text: "L'utilisation du site tramway.life entraîne l'acceptation inconditionnelle et automatique par l'utilisateur de la totalité des présentes Conditions d'Utilisation ainsi que l'engagement de les respecter. Toute violation des Conditions d'Utilisation peut entraîner la suspension ou la résiliation du compte utilisateur, sans préavis et sans remboursement des services payants souscrits. En cas de violation de nos Conditions d'Utilisation, nous proposons plusieurs mesures de sanction, y compris la possibilité de clôturer votre compte, de permettre aux des membres le blocage de membres, le bannissement partiel qui restreint l'envoi et la réception de messages, ainsi que la suspension temporaire du compte jusqu'à modération.",
        title: "Acceptation des conditions",
      },
      {
        id: uuidv4(),
        text: "Tramway se réserve le droit de modifier et de compléter les présentes Conditions d'Utilisation à tout moment, sans préavis et sans contrepartie. La reconnexion et l'utilisation continue du site tramway.life entraînent une ré-acceptation systématique des Conditions d'Utilisation et de leur respect. Les membres devront alors prendre activement connaissance de ces amendements. Par conséquent, il relève de la responsabilité de l'utilisateur de prendre connaissance des nouvelles actualisations de ces conditions à chaque nouvelle connexion au site tramway.life. Les éventuelles modifications sont réputées lues et acceptées par l'utilisateur.",
        title: "Modification des conditions",
      },
      {
        id: uuidv4(),
        text: "En cas de modification des Conditions Générales d'Utilisation, Tramway informera ses Membres dans la semaine suivant cette modification. Il est de la responsabilité des Membres de prendre activement connaissance de ces amendements et d'accepter les nouvelles Conditions Générales d'Utilisation pour continuer à utiliser les services Tramway. Toute modification du présent contrat est réputée lue et acceptée par le Membre passé un délai de 1 mois suivant sa modification.",
        title: "Notifications des Modifications des conditions",
      },
      {
        id: uuidv4(),
        text: "Ces Conditions d'Utilisation comportent des exclusions de garantie et des limitations de responsabilité dont l'utilisateur déclare avoir pris connaissance. Tramway ne saurait être tenu responsable des dommages indirects, imprévisibles ou résultant d'une force majeure.",
        title: "Exclusions de responsabilité",
      },
      {
        id: uuidv4(),
        text: "Résolution amiable des litiges – juridiction compétente : En cas de litige découlant de l'interprétation ou de l'exécution des présentes conditions générales d'utilisation et de vente, les parties s'engagent à rechercher activement une solution amiable. À défaut d'un règlement amiable, toute réclamation dirigée contre Tramway sera de la compétence exclusive des tribunaux de Lille. Cette compétence s'étend également à tout cas de pluralité de défendeurs ou d'appel en garantie.",
        title: "Loi applicable et juridiction compétente",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Description des services Tramway",
    children: [
      {
        id: uuidv4(),
        text: "Les présentes conditions générales ont pour objet de définir les modalités de mise à disposition des services du site tramway.life et les conditions d'utilisation du service par les utilisateurs.",
        title: "Modalités de mise à disposition",
      },
      {
        id: uuidv4(),
        text: "Tramway est un site de rencontre à des fins strictement personnelles, récréatives et non commerciales/professionnelles entre personnes majeures désireuses d'initier une relation virtuelle de nature amicale ou sentimentale, fondée sur un intérêt commun pour la protection de l'environnement et la nature. Les services sont accessibles via le site internet tramway.life.",
        title: "Nature des services",
      },
      {
        id: uuidv4(),
        text: "Afin d'assurer un service de qualité, Tramway établit des profils personnalisés de ses membres, c'est-à-dire les personnes inscrites sur le site et disposant d'un pseudonyme ou d'un numéro de membre (ce dernier étant non modifiable par le membre lui-même) et d'une adresse email valide. Grâce à ces informations, chaque membre aura accès à la plateforme tramway.life.",
        title: "Profils personnalisés",
      },
      {
        id: uuidv4(),
        text: "Tout accès et/ou utilisation du site tramway.life suppose l'acceptation et le respect de la totalité des termes des présentes conditions d'utilisation par l'utilisateur.",
        title: "Acceptation des conditions",
      },
      {
        id: uuidv4(),
        text: "L'engagement de respecter les présentes conditions s'exprime après avoir coché les cases suivantes : 'J'ai lu, j'ai compris et j'accepte les cookies, les CGV et les CGU, et la politique de confidentialité'. Cette acceptation de la part de l'utilisateur est obligatoire pour accéder aux services.",
        title: "Engagement de l'utilisateur",
      },
      {
        id: uuidv4(),
        text: "L'accession au statut de membre, une fois la procédure d'inscription suivie, est assujettie à la validation par le service de modération de Tramway. Tramway se réserve le droit de refuser ou de résilier un compte utilisateur sans préavis si les informations fournies sont incorrectes ou si le comportement de l'utilisateur contrevient aux présentes conditions.",
        title: "Validation de l'inscription",
      },
      {
        id: uuidv4(),
        text: "La description des services, gratuits ou payants, est disponible sur la page 'Offre' du site tramway.life. Il est possible de clôturer son compte à tout moment depuis la page 'Compte' sur le site. Aucune indemnité ne sera due en cas de clôture de compte par l'utilisateur ou par Tramway.",
        title: "Description des services",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Accès au site",
    children: [
      {
        id: uuidv4(),
        text: "Il appartient à l’utilisateur de prendre toutes les mesures appropriées pour protéger ses propres données et/ou logiciels de la contamination par d’éventuels virus circulant sur le réseau Internet. Tramway décline toute responsabilité en cas de dommage survenu pendant la consultation du site ou après un téléchargement effectué à partir du présent site.",
        title: "Protection des données et des logiciels",
      },
      {
        id: uuidv4(),
        text: "L'utilisateur reconnaît avoir été informé que le présent site est accessible 24 heures sur 24 et 7 jours sur 7, sauf en cas de force majeure, de difficultés informatiques ou de difficultés techniques. La responsabilité de Tramway ne peut être engagée en raison d'une indisponibilité technique de la connexion, que celle-ci soit due notamment à des opérations de maintenance, à des mises à jour, à des interruptions du réseau ou à tout autre problème indépendant de notre volonté.",
        title: "Disponibilité du site",
      },
      {
        id: uuidv4(),
        text: "Nous ne pouvons assurer la confidentialité des messages que vous pouvez nous adresser transitant par un réseau ouvert de télécommunications. Il est de la responsabilité de l'utilisateur de prendre les mesures appropriées pour garantir la confidentialité de ses communications.",
        title: "Confidentialité des communications",
      },
      {
        id: uuidv4(),
        text: "Les liens vers d’autres sites ne sont communiqués qu’à titre indicatif et ne sauraient en aucun cas engager la responsabilité de Tramway, tant en ce qui concerne leur contenu que les conditions d’accès. L'utilisateur accède à ces sites sous sa propre responsabilité.",
        title: "Liens vers d'autres sites",
      },
      {
        id: uuidv4(),
        text: "Tous les coûts afférents à l'accès au site tramway.life, tels que les logiciels, frais d'accès à Internet, et autres coûts de réseau, sont exclusivement à la charge de l'utilisateur.",
        title: "Frais d'accès",
      },
      {
        id: uuidv4(),
        text: "Certaines parties du site sont réservées aux membres après identification à l'aide d'une adresse email valide et confidentielle. Une option gratuite de double authentification est également disponible pour renforcer la sécurité. Ces membres sont ci-après dénommés « membres inscrits et/ou abonnés.",
        title: "Parties réservées aux membres",
      },
      {
        id: uuidv4(),
        text: "Tramway se réserve le droit de refuser l'accès au site, unilatéralement et sans préavis, à tout utilisateur qui ne respecterait pas les présentes conditions générales d'utilisation, sans préjudice de tous dommages et intérêts éventuels.",
        title: "Refus d'accès",
      },
      {
        id: uuidv4(),
        text: "Tramway se réserve la possibilité d'interrompre, de suspendre ou de modifier sans préavis l'accès à tout ou partie du site, notamment afin d'en assurer la maintenance, sans que cette interruption ne puisse être reprochée à Tramway ni donner lieu à une quelconque indemnisation.",
        title: "Interruption du service",
      },
      {
        id: uuidv4(),
        text: "Le site tramway.life est accessible depuis un ordinateur fixe ou sur un appareil portable.",
        title: "Accessibilité sur différents appareils",
      },
      {
        id: uuidv4(),
        text: "Le site tramway.life est également accessible sur smartphone depuis le navigateur open-source Chromium. L'installation de l'application vous sera alors proposée automatiquement par Chromium.",
        title: "Accessibilité sur smartphone",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Validité des informations fournies",
    children: [
      {
        id: uuidv4(),
        text: "Dans l'hypothèse où l'utilisateur ou le membre serait amené à fournir des informations à Tramway, il s'engage à :",
        title: "Introduction",
      },
      {
        id: uuidv4(),
        text: "Fournir des informations réelles, exactes et à jour lors de leur saisie dans le formulaire d'inscription du service, et s'abstenir notamment d'utiliser de faux noms ou adresses, ou encore des noms ou adresses sans y être autorisé.",
        title: "Informations fournies",
      },
      {
        id: uuidv4(),
        text: "Maintenir à jour les données d'inscription afin de garantir en permanence leur caractère réel, exact et à jour.",
        title: "Mise à jour des informations",
      },
      {
        id: uuidv4(),
        text: "Signaler à Tramway tout manquement à l'éthique, toute erreur ou toute information inexacte ou susceptible de contrevenir aux lois en vigueur, afin que Tramway puisse corriger ou faire corriger l'erreur dans les plus brefs délais.",
        title: "Signalement des erreurs",
      },
      {
        id: uuidv4(),
        text: "S'abstenir de rendre disponible ou distribuer des informations susceptibles de porter atteinte au respect de la vie privée, des informations illégales ou répréhensibles, telles que des informations diffamatoires, obscènes, nuisibles, frauduleuses ou illégales.",
        title: "Contenu à ne pas diffuser",
      },
      {
        id: uuidv4(),
        text: "Respecter les droits de propriété intellectuelle et ne pas diffuser de contenu pouvant porter atteinte à ces droits.",
        title: "Respect de la propriété intellectuelle",
      },
      {
        id: uuidv4(),
        text: "S'interdire d'utiliser le site à des fins professionnelles répréhensibles ou non (prostitution, proxénétisme, racolage, etc.), ainsi que de développer ou proposer des services payants ou ayant une contrepartie de nature financière.",
        title: "Utilisation du site",
      },
      {
        id: uuidv4(),
        text: "S'abstenir d'utiliser le site pour le compte d'une association ou de tout autre organisme.",
        title: "Utilisation pour des tiers",
      },
      {
        id: uuidv4(),
        text: "Ne pas contrevenir aux lois en vigueur et respecter les règles éthiques et légales établies par Tramway.",
        title: "Respect des lois et des règles éthiques",
      },
      {
        id: uuidv4(),
        text: "En cas de violation de ces dispositions, Tramway se réserve le droit de suspendre ou de résilier l'accès du membre aux services à ses torts exclusifs.",
        title: "Sanctions en cas de violation",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Données Sensibles",
    children: [
      {
        id: uuidv4(),
        text: "Nous tenons à rappeler à nos membres que les informations concernant le handicap, l'orientation sexuelle, l'origine ethnique, la religion et la santé sont considérées comme des données sensibles. Sous la responsabilité du membre, ces informations peuvent être visibles sur le site tramway.life par les autres membres. En acceptant nos Conditions Générales d'Utilisation, chaque membre confirme expressément son consentement au traitement de ces données par Tramway et en assume l'entière responsabilité.",
        title: "RGPD : Données Sensibles explicites",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Conduite des utilisateurs et membres",
    children: [
      {
        id: uuidv4(),
        text: "L'utilisateur s'engage à ne pas diffuser de propos, opinions ou informations à caractère diffamatoire, obscène, violent, raciste ou contraire aux lois en vigueur, aux droits des personnes et aux principes éthiques.",
        title: "Contenu respectueux",
      },
      {
        id: uuidv4(),
        text: "Il est également interdit à l'utilisateur de harceler un autre utilisateur ou membre, et de continuer à contacter un utilisateur qui a exprimé son refus de tout contact. De plus, l'utilisateur s'engage à ne jamais détourner les fonctionnalités du site de leur usage initial.",
        title: "Respect des limites de contact",
      },
      {
        id: uuidv4(),
        text: "La divulgation de données personnelles telles que les numéros de téléphone, adresses électroniques, adresses postales, noms d'utilisateur ou tout autre renseignement confidentiel concernant l'utilisateur ou d'autres utilisateurs du site, sans leur consentement explicite, est strictement interdite.",
        title: "Confidentialité des données personnelles",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Limitation de responsabilité",
    children: [
      {
        id: uuidv4(),
        text: "Tramway ne peut vérifier l'identité de chaque Membre et ne peut être tenue responsable d'éventuelles inexactitudes à cet égard. L'objectif de Tramway n'est pas d'organiser des rencontres physiques ni d'être courtier matrimonial ni d'être un conseiller en vue d'une union. Tramway est un Logiciel en tant que service permettant à ses Membres de faire des rencontres virtuelles dans un environnement serein et convivial.",
        title: "Identification des membres et objectif de Tramway",
      },
      {
        id: uuidv4(),
        text: "Tramway n'assume aucune obligation de résultat et se décharge de toute responsabilité quant aux rencontres physiques entre ses Membres. Les Membres sont encouragés à faire preuve de prudence et de bon sens lorsqu'ils décident de rencontrer physiquement d'autres Membres rencontrés via la plateforme Tramway.",
        title: "Responsabilité en cas de rencontres physiques",
      },
      {
        id: uuidv4(),
        text: "Tramway met à disposition trois pages importantes pour ses utilisateurs : 1) le Manifesto, où sont exposées les valeurs et principes fondamentaux du service, 2) l'Accessibilité, qui informe sur les moyens mis en place pour garantir l'accessibilité du service à tous les utilisateurs, et 3) les Mentions légales, qui détaillent les informations légales concernant l'entreprise et le service. De plus, vous disposez à la fois d'un glossaire des termes techniques et sectoriels pour une meilleure compréhension du service, ainsi que d'un moteur de recherche interne pour faciliter la navigation et la recherche d'informations spécifiques.",
        title: "En savoir plus",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "RGPD",
    children: [
      {
        id: uuidv4(),
        text: "Tramway traite les données personnelles de ses membres avec le plus grand respect de la vie privée, conformément à sa Charte de confidentialité. Nous vous invitons à consulter notre Politique générale de Protection des Données à caractère personnel du Tramway pour en savoir plus sur la manière dont nous traitons vos données personnelles.",
        title: "Politique générale de Protection des Données à caractère personnel du Tramway",
      },
    ],
  },

  {
    id: uuidv4(),
    title: "Durée",
    children: [
      {
        id: uuidv4(),
        text: "Les présentes conditions générales d'utilisation sont valables pour une durée indéterminée. En accédant, naviguant et utilisant le site internet Tramway, chaque membre et utilisateur accepte sans exception ni réserve les termes et conditions des présentes Conditions Générales d'Utilisation.",
        title: "Durée",
      },
    ],
  },
]);

export { CGU };
