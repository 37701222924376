import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";

const useReview = () => {
  const [averageRate, setAverageRate] = useState(4.5);
  const [error, setError] = useState(null);
  const fetchAverageRate = async () => {
    try {
      const { data, error } = await supabase.rpc("get_rounded_average_of_last_300_reviews");

      if (error) {
        setError(error);
      } else {
        setAverageRate(data);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchAverageRate();
  }, []);

  const addReview = async (rateValue) => {
    try {
      const { error } = await supabase
        .from("reviews")
        .insert([{ rate: rateValue }])
        .select();

      if (error) {
        setError(error);
      } else {
        fetchAverageRate();
      }
    } catch (error) {
      setError(error);
    }
  };

  return { averageRate, error, addReview, setError, setAverageRate };
};

export default useReview;
