const PUBLIC_ROUTES = Object.freeze({
  index: "/",
  sitemap: "sitemap",
  error404: "*",
  error403: "error403",
  connexion: "connexion",
  creerCompte: "creercompte",
  manifesto: "manifesto",
  avis: "avis",
  feed: "feed",
  contact: "contact",
  aide: "aide",
  mentionsLegales: "mentionslegales",
  accessibilite: "accessibilite",
  FAQ: "faq",
  CGV_CGU: "CGV_CGU",
});

export { PUBLIC_ROUTES };
