import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

function Seo({ title, description, name, type, isPrivate, keywords, pathname }) {
  // noindex : Ne pas indexer la page
  // nofollow : Ne pas suivre les liens
  // nosnippet : Pas de snippet dans les résultats
  // noarchive : Ne pas archiver la page
  // noodp : Pas de description de l'Open Directory Project ODP
  // noimageindex : Ne pas indexer les images de la page
  // notranslate : Ne pas traduire le contenu de la page
  const metaRobotsContent = isPrivate
    ? "noindex, nofollow, nosnippet, noarchive, noodp, noimageindex, notranslate"
    : "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1";

  return (
    <Helmet>
      <title>{`${title} • ${name}`}</title>
      <meta name="dc.title" content={`${title} • ${name}`} />

      <meta name="dc.relation" content={pathname} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Balises robots */}
      <meta name="robots" content={metaRobotsContent} />
      <meta name="googlebot" content={metaRobotsContent} />
      <meta name="bingbot" content={metaRobotsContent} />
      <meta name="duckduckbot" content={metaRobotsContent} />
      <meta name="baidu" content={metaRobotsContent} />
      <meta name="yandex" content={metaRobotsContent} />

      {/* Métadonnées pour les fonctionnalités PWA */}
      <meta name="application-name" content={name} />
      <meta name="apple-mobile-web-app-title" content={`${title} • ${name}`} />

      {/* Balises Open Graph pour les réseaux sociaux */}
      <meta property="og:type" content={type} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={`${title} • ${name}`} />

      {/* Twitter */}
      <meta name="twitter:creator" content={name} />
      <meta property="twitter:title" content={`${title} • ${name}`} />
      <meta property="twitter:description" content={description} />
    </Helmet>
  );
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isPrivate: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.bool.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),

  keywords: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default Seo;
