import {
  Well,
  Button,
  Tooltip,
  TooltipTrigger,
  Flex,
  Link,
  Heading,
  Content,
  ContextualHelp,
  View,
  Text,
  Footer,
} from "@adobe/react-spectrum";

import TramFlashIcon from "./TramFlashIcon";
import Follow from "@spectrum-icons/workflow/Heart";

function PublicSubFooter() {
  const currentYear = new Date().getFullYear();

  // Fonction pour afficher une section "Well"
  const renderWell = (texte) => <Well marginY="size-100">{texte}</Well>;

  return (
    <View marginY="size-100" width={{ base: "100%", L: "25%" }}>
      <Flex direction="column" wrap marginY="size-100" alignItems="start" justifyContent="center" gap="size-250">
        <Flex alignItems="center" justifyContent="center">
          {/* Icône personnalisée avec description */}
          <TramFlashIcon aria-label="Icône de chemin intérieur et de parcours" />
          <Heading margin="size-10" level={3}>
            Tramway
          </Heading>

          {/* Aide contextuelle */}
          <ContextualHelp variant="info">
            <Heading>
              Tramway • courant alternatif <span>&#x00AE;</span>
            </Heading>
            <Content>
              {renderWell(
                `Tramway est une marque française déposée à l'INPI sous le N° 5027214 • 2023-${currentYear} tous droits réservés.`,
              )}
              {renderWell(
                `Tramway est une plateforme indépendante créée par Bluecornflakes : entreprise non-diffusible, Article A123-96 du Code de Commerce.`,
              )}
              {renderWell(
                "Les membres doivent être légalement majeurs et responsables, conformément à la loi. Utilisez Tramway de manière légale et positive.",
              )}
            </Content>

            <Footer>
              <Text>
                <span lang="en">
                  Bluecornflakes • digital native <span title="emoji : pieds nus">👣</span>
                </span>
              </Text>
            </Footer>
          </ContextualHelp>
        </Flex>

        <Text>
          Petite plateforme de rencontre accessible mais bien &quot;Roots&quot;. Nous célébrons les alternatives et les
          jus de légumes
          {` • 2023-${currentYear} tous droits réservés.`}
        </Text>

        <TooltipTrigger delay={0}>
          <Link rel="noopener noreferrer" target="_blank" isQuiet={true} href="https://www.bluecornflakes.com">
            {" "}
            <Button
              UNSAFE_style={{
                transform: "rotate(-2deg)",
                borderRadius: "20% 25% 20% 24%",
              }}
              variant="accent"
              aria-label="Visiter Bluecornflakes"
            >
              <Follow /> <Text>By Bluecornflakes</Text>
            </Button>
          </Link>
          <Tooltip>App Maker : Bluecornflakes</Tooltip>
        </TooltipTrigger>
      </Flex>
    </View>
  );
}

export default PublicSubFooter;
