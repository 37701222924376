import { useState, lazy, Suspense, memo } from "react";

import Loading from "../../components/Loading";
const SetlazyMapDialog = memo(lazy(() => import("../../components/GeoCode/MapDialog")));

import Location from "@spectrum-icons/workflow/Location";

import { DialogContainer, ActionButton, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

function FullScreenMapBtn() {
  let [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* MAP */}
      <TooltipTrigger>
        <ActionButton onPress={() => setOpen(true)} aria-label="Carte interactive des membres" marginY="size-200">
          <Location aria-label="Carte géographique basé sur les utilisateurs" />
        </ActionButton>
        <Tooltip>Carte des membres</Tooltip>
      </TooltipTrigger>
      <DialogContainer type="fullscreenTakeover" onDismiss={() => setOpen(false)}>
        {isOpen && (
          <Suspense fallback={<Loading />}>
            <SetlazyMapDialog />
          </Suspense>
        )}
      </DialogContainer>
    </>
  );
}

export default FullScreenMapBtn;
