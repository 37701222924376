import { useState } from "react";

import { RGPD } from "../constants/RGPD";

import { Item, ListBox, Text, View, Section } from "@adobe/react-spectrum";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";

export default function Rgpd() {
  const [word, setWord] = useState(new Set());
  return (
    <>
      <View key={word}>
        <ListBox
          selectionMode="multiple"
          aria-label="Les traitements RGPD point par point."
          items={RGPD}
          onSelectionChange={(selected) => setWord(selected)}
        >
          {(item) => (
            <Section key={item?.id} items={item.children} title={item.title}>
              {(item) => (
                <Item
                  aria-label={item?.title}
                  isQuiet
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  textValue={item?.title}
                >
                  <ChevronRight />
                  <Text>
                    {item?.title + " "} <span title={"en " + item?.lang}>{item?.flag}</span>
                  </Text>
                  <Text slot="description">{item?.text}</Text>
                </Item>
              )}
            </Section>
          )}
        </ListBox>
      </View>
    </>
  );
}
