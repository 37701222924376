const PRIVATE_ROUTES = Object.freeze({
  private: "private",
  messages: "messages",
  cercles: "circles",
  flash: "flash",
  accueil: "accueil",
  profile: "profile",
});

export { PRIVATE_ROUTES };
