import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Image,
  Divider,
  Content,
  Well,
  Link,
  Item,
  Heading,
  Text,
  Dialog,
  ComboBox,
  Section,
} from "@adobe/react-spectrum";
import { useTreeData } from "react-stately";
import { glossaire } from "../constants/glossaire";
import { FAQ_TRAMWAY } from "../constants/faq";
import { CGV } from "../constants/CGV";
import { CGU } from "../constants/CGU";
import { RGPD } from "../constants/RGPD";
import HandleOnSelectionChange from "../config/HandleSelectionChange";

export default function SearchTray() {
  const navigate = useNavigate();

  let options = [
    {
      id: 1,
      title: "Section Glossaire",
      children: glossaire,
    },
    {
      id: 2,
      title: "Section FAQ's",
      children: [...FAQ_TRAMWAY[0].children, ...FAQ_TRAMWAY[1].children, ...FAQ_TRAMWAY[2].children],
    },
    {
      id: 3,
      title: "Section CGV",
      children: (() => {
        const children = [];
        for (let i = 0; i <= 34; i++) {
          children.push(...CGV[i].children);
        }
        return children;
      })(),
    },
    {
      id: 4,
      title: "Section CGU",
      children: (() => {
        const children = [];
        for (let i = 0; i <= 8; i++) {
          children.push(...CGU[i].children);
        }
        return children;
      })(),
    },
    {
      id: 5,
      title: "Section RGPD",
      children: (() => {
        const children = [];
        for (let i = 0; i <= 8; i++) {
          children.push(...RGPD[i].children);
        }
        return children;
      })(),
    },
  ];

  let [fieldState, setFieldState] = useState({
    selectedKey: "",
    inputValue: "",
    inputDescription: "",
    inputLink: "",
  });

  let list = useTreeData({
    initialItems: options.map((section) => ({
      key: section.id,
      title: section.title,
      children: section.children.map((child) => ({
        key: child?.id ?? child?.title,
        title: child?.title,
        text: child?.text,
      })),
    })),
  });

  let onSelectionChange = (key) => {
    setFieldState({
      selectedKey: key,
      inputValue: list.getItem(key)?.value.title ?? "",
      inputDescription: list.getItem(key)?.value.text ?? "",
      inputLink: list.getItem(key)?.value.link ?? "",
    });
  };

  let onInputChange = (value) => {
    setFieldState((prevState) => ({
      inputValue: value,
      selectedKey: value === "" ? null : prevState?.selectedKey,
    }));
  };

  return (
    <>
      <Dialog>
        <Image
          slot="hero"
          alt={`Une image aléatoire représentant la beauté de la nature`}
          src={`https://picsum.photos/id/10/1200/600`}
          objectFit="cover"
        />
        <Heading>Faire une recherche</Heading>

        <Divider />
        <Content>
          <ComboBox
            width="100%"
            aria-label="champ de recherche..."
            defaultItems={list?.items}
            selectedKey={fieldState?.selectedKey}
            inputValue={fieldState?.inputValue}
            onSelectionChange={onSelectionChange}
            onInputChange={onInputChange}
          >
            {(item) => (
              <Section
                key={item?.key} // Ensure Section has a unique key
                items={item?.value?.children || []} // Ensure children is always an array
                title={item?.value?.title}
              >
                {(childItem) => <Item key={childItem?.key}>{childItem?.title}</Item>}
              </Section>
            )}
          </ComboBox>

          {fieldState.inputDescription && (
            <Well marginY="size-250">
              <Heading level={3}>{fieldState?.inputValue}</Heading>
              <Text>{fieldState?.inputDescription}</Text>
              {fieldState?.inputLink && (
                <Heading level={4}>
                  Lien vers la ressource :{" "}
                  <Link variant="secondary" onPress={() => HandleOnSelectionChange(fieldState?.inputLink, navigate)}>
                    {fieldState?.inputLink}
                  </Link>
                </Heading>
              )}
            </Well>
          )}
        </Content>
      </Dialog>
    </>
  );
}
