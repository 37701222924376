import { useEffect } from "react";

const useVibration = () => {
  const isVibrationSupported = "vibrate" in navigator;

  const vibrate = () => {
    if (isVibrationSupported) {
      navigator.vibrate(200);
    }
  };

  useEffect(() => {
    return () => {
      navigator.vibrate(0);
    };
  }, []);

  return { vibrate, isVibrationSupported };
};

export default useVibration;
