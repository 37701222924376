import { PUBLIC_ROUTES } from "../constants/publicroutes";
import { PRIVATE_ROUTES } from "../constants/privateroutes";

const HandleOnSelectionChange = (e, navigate) => {
  const key = e?.currentKey ?? e;
  switch (key) {
    case PUBLIC_ROUTES.manifesto:
      navigate("../" + PUBLIC_ROUTES.manifesto);
      break;
    case PRIVATE_ROUTES.compte:
      navigate("../private/" + PRIVATE_ROUTES.compte);
      break;
    case PUBLIC_ROUTES.offre:
      navigate("../" + PUBLIC_ROUTES.offre);
      break;
    case PUBLIC_ROUTES.CGV_CGU:
      navigate("../" + PUBLIC_ROUTES.CGV_CGU);
      break;
    case PUBLIC_ROUTES.contact:
      navigate("../" + PUBLIC_ROUTES.contact);
      break;
    case PUBLIC_ROUTES.accessibilite:
      navigate("../" + PUBLIC_ROUTES.accessibilite);
      break;
    default:
      return; // NO RELOAD
  }
};

export default HandleOnSelectionChange;
