import PropTypes from "prop-types";

import { Well, ActionButton, IllustratedMessage, Content, Heading, Text } from "@adobe/react-spectrum";

import Bug from "@spectrum-icons/workflow/Bug";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <>
      <IllustratedMessage height="50%" marginTop="size-100">
        <Heading>Nous rencontrons une erreur.</Heading>
        <Content>
          on fait le maximum <br />
          pour résoudre ça !
        </Content>
        <ActionButton onPress={resetErrorBoundary} marginY="size-200">
          <Bug />
          <Text>Essayez à nouveau</Text>
        </ActionButton>
      </IllustratedMessage>
      <Well>
        LE MESSAGE D&apos; ERREUR : <br /> {error.message}
      </Well>
    </>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};
