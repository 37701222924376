import { lazy } from "react";
import PropTypes from "prop-types";

const useLazyLoad = (component, delay = 0) => {
  const lazyComponent = lazy(() => new Promise((resolve) => setTimeout(() => resolve({ default: component }), delay)));

  return lazyComponent;
};

useLazyLoad.propTypes = {
  component: PropTypes.node.isRequired,
  delay: PropTypes.number,
};

export default useLazyLoad;
