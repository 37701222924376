import PropTypes from "prop-types";
import { IllustratedMessage, View } from "@adobe/react-spectrum";

const Loading = ({ className, ...props }) => {
  return (
    <IllustratedMessage width="95vw" height="95vh">
      <View aria-label="Chargement du contenu..." UNSAFE_className={className ? className : "heart"} {...props} />
    </IllustratedMessage>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
};

Loading.displayName = "Loading";
export default Loading;
