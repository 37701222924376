import PropTypes from "prop-types";
import { Flex, InlineAlert, Heading, Content, ProgressBar } from "@adobe/react-spectrum";
import EmailCheck from "@spectrum-icons/workflow/EmailCheck";

function InlineAlertForm({ messageVariant, message, tooManyRequest, count, maxValue }) {
  return (
    <Flex justifyContent="center">
      <InlineAlert variant={messageVariant ?? "neutral"} marginY="size-400" width="100%">
        <Heading>Information de validation</Heading>
        <Flex justifyContent="space-evenly" alignItems="center" gap="size-200">
          <EmailCheck aria-label={message ?? "Un email est arrivé dans votre boite"} size="M" />

          <Content width="66%">
            {tooManyRequest && messageVariant === "notice" ? (
              <ProgressBar width="100%" label={message} value={count} maxValue={maxValue} />
            ) : (
              <>{message}</>
            )}
          </Content>
        </Flex>
      </InlineAlert>
    </Flex>
  );
}

InlineAlertForm.propTypes = {
  messageVariant: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  tooManyRequest: PropTypes.bool,
  maxValue: PropTypes.number,
  count: PropTypes.number,
};

export default InlineAlertForm;
