import { Tweet } from "react-tweet";
import { Flex, View } from "@adobe/react-spectrum";
const TwitterTimeline = () => {
  return (
    <Flex gap="size-100" alignItems="start" wrap width="100%" justifyContent="center">
      <View width={{ base: "100%", S: "100%", M: "45%", L: "30%" }}>
        {" "}
        <Tweet id="1865666635262026000" />
      </View>{" "}
      <View width={{ base: "100%", S: "100%", M: "45%", L: "30%" }}>
        <Tweet id="1783462672551301590" />
      </View>{" "}
      <View width={{ base: "100%", S: "100%", M: "45%", L: "30%" }}>
        <Tweet id="1866192145192829243" />
      </View>
      <View width={{ base: "100%", S: "100%", M: "45%", L: "30%" }}>
        <Tweet id="1865672588166639626" />
      </View>{" "}
      <View width={{ base: "100%", S: "100%", M: "45%", L: "30%" }}>
        <Tweet id="1779022517975298517" />
      </View>
      <View width={{ base: "100%", S: "100%", M: "45%", L: "30%" }}>
        {" "}
        <Tweet id="1865448691089236271" />
      </View>
    </Flex>
  );
};

export default TwitterTimeline;
