import Rgpd from "./Rgpd";
import LoremPicsum from "./LoremPicsum";
import {
  Heading,
  useDialogContainer,
  ButtonGroup,
  Button,
  Dialog,
  Well,
  Text,
  Divider,
  Content,
  Flex,
} from "@adobe/react-spectrum";

function SetDPODialog() {
  let dialog = useDialogContainer();

  return (
    <Dialog>
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <Text>Politique générale de Protection des Données à caractère personnel du Tramway</Text>
        </Flex>
      </Heading>
      <Divider />
      <Content>
        <LoremPicsum size={1200} id={22} rgpd={true} />
        <Well marginBottom="size-250">
          <Heading>Notre vision</Heading>
          <Text>
            Nous nous efforçons de rendre cette politique de confidentialité aussi claire, concise et facile à
            comprendre que possible. Nous nous engageons à être transparents et ouverts. Cet article explique de manière
            générale comment nous recevons les informations vous concernant et ce que nous faisons de ces informations
            une fois qu&apos;elles sont en notre possession.
          </Text>

          <Heading>Nos obligations</Heading>
          <Text>
            Le présent registre des activités de traitement permet de recenser nos traitements de données et de disposer
            d’une vue d’ensemble de ce que nous faisons avec vos données personnelles. Le registre est prévu par
            l’article 30 du Règlement Général sur la Protection des Données (RGPD) et participe à la documentation de
            notre conformité.
          </Text>

          <Heading>Préambule</Heading>
          <Text>
            La présente « Politique de Protection des Données à Caractère Personnel » (la « Politique ») a pour objet
            d’informer toutes les personnes physiques concernées (« Vous » ou « Votre/Vos ») sur la manière dont Tramway
            (« Entreprise Bluecornflakes ») traite de telles Données à caractère personnel en tant que responsable de
            traitement, ainsi que sur les droits dont vous disposez à ce titre.
          </Text>
        </Well>

        <Rgpd />
      </Content>
      <ButtonGroup>
        <Button variant="accent" onPress={dialog.dismiss}>
          Quitter
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

export default SetDPODialog;
