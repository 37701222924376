import PropTypes from "prop-types";

import { Icon } from "@adobe/react-spectrum";

function TramFlashIcon(props) {
  return (
    <Icon {...props}>
      <svg width={36} height={36} {...props}>
        <path
          d="M21.535 25.625c-4.988.203-6.598 1.574-9.933 1.574-3.145 0-5.57-1.597-6.403-1.597V30.8h25.602v-3.602c-6.043 0-7.664-1.36-9.266-1.574ZM11.602 15.2c.214.01.43.03.644.058a8.326 8.326 0 0 1 1.273.27 8.1 8.1 0 0 1 .614.21 8.1 8.1 0 0 1 1.172.563 8.138 8.138 0 0 1 1.07.738 5.621 5.621 0 0 0 1.508.93c.273.117.555.21.84.281.289.074.582.125.879.148.62 0 .91-3.406 1.03-4.859.032-.367.063-.746.102-1.133a1.272 1.272 0 0 1-.093.156c-.032.051-.07.098-.11.145a1.886 1.886 0 0 1-.265.238 1.52 1.52 0 0 1-.488.223c-.06.016-.118.023-.176.031-2.618 0-4.493-3.199-8-3.199-4.786 0-4.266 3.2-6.403 3.2v5.198c.614 0 1.07-.39 1.824-1.085.145-.16.297-.313.457-.458a6.215 6.215 0 0 1 1.043-.77 6.097 6.097 0 0 1 1.172-.542 6.998 6.998 0 0 1 1.258-.293c.215-.027.43-.043.649-.05Zm0 0"
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "#c79557",
            fillOpacity: 1,
          }}
        />
        <path
          d="M23.023 13.742c-.289 3.469-.593 7.059-3.421 7.059a7.943 7.943 0 0 1-4.574-1.777 6.072 6.072 0 0 0-1.602-.984 5.555 5.555 0 0 0-.895-.29 5.83 5.83 0 0 0-.93-.148c-.144.003-.285.02-.421.043a3.05 3.05 0 0 0-.418.085c-.137.036-.27.079-.403.13a3.794 3.794 0 0 0-1.101.624c-.113.086-.219.18-.32.282a3.607 3.607 0 0 0-.286.312c-.109.125-.222.242-.34.36a5.8 5.8 0 0 1-.374.32c-.133.105-.266.199-.407.285-.14.09-.285.172-.43.246-.148.07-.3.137-.453.195a6.69 6.69 0 0 1-.472.157c-.16.043-.32.078-.485.101a5.281 5.281 0 0 1-.492.059v2.398c.274.004.547.028.817.063a7.15 7.15 0 0 1 1.582.41c.254.098.504.21.742.34.25.133.508.25.773.347.262.102.532.184.809.25a6.624 6.624 0 0 0 1.68.192 13.47 13.47 0 0 0 3.543-.582c1.984-.551 4-.88 6.054-.977a2.77 2.77 0 0 1 .36-.02A41.05 41.05 0 0 1 22.8 23.2c.523 0 .746-.629 1.039-2.922.336-2.66.844-6.675 5.36-6.675h1.6V6.398H26c-2.188 0-2.613 3.043-2.977 7.344Zm0 0"
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "#c79557",
            fillOpacity: 1,
          }}
        />
        <path
          d="M26.219 20.578c-.04.629-.14 1.25-.301 1.86-.164.609-.383 1.199-.66 1.765.906.219 1.824.379 2.754.477.925.101 1.855.14 2.789.12V16h-1.602c-2.176 0-2.578 1.395-2.98 4.578Zm0 0"
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "#c79557",
            fillOpacity: 1,
          }}
        />
      </svg>
    </Icon>
  );
}

TramFlashIcon.propTypes = {
  color: PropTypes.string,
};

export default TramFlashIcon;
