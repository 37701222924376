import PropTypes from "prop-types";
import useSWR from "swr";
import { supabase } from "../../supabaseClient";
import { ErrorBoundary } from "react-error-boundary";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { lazy, Suspense, memo, useContext, useMemo, useState } from "react";

import SectionScrollable, { ScrollTo } from "react-scroll-id";
import { useHotkeys } from "react-hotkeys-hook";

import Seo from "./Seo";
import Breadcrumb from "./Breadcrumb";
import Loading from "../Loading";

import UserSession from "../../contexts/UserSession";
import UserAccessibility from "../../contexts/UserAccessibility";

import GetLayoutData from "../../config/GetLayoutData";
import { PRIVATE_ROUTES } from "../../constants/privateroutes";
import { PUBLIC_ROUTES } from "../../constants/publicroutes";

import ErrorFallback from "../ErrorFallback";
import PublicFooter from "../PublicFooter";

import { Heading, View, Flex, Text, Button, DialogTrigger, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

import ShoppingCart from "@spectrum-icons/workflow/ShoppingCart";

const SetlazyOffer = memo(lazy(() => import("../../components/Offer")));

// Supabase fetcher function
const fetchSubscriptionTypes = async () => {
  const { data, error } = await supabase.from("subscriptions").select("subscription_type");
  if (error) throw error;
  return data || [];
};

export default function Layout({ children }) {
  const { toggleTheme, toggleMenu, openDyslexic } = useContext(UserAccessibility);
  const { sessionRole } = useContext(UserSession);
  const nav = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  // SWR Hook for subscription types
  const { data: subscriptions, error } = useSWR("subscriptions", fetchSubscriptionTypes);

  // Determine if user has an active subscription
  const [activeSubscriptionType, setActiveSubscriptionType] = useState(null);

  useMemo(() => {
    if (subscriptions?.length > 0) {
      const activeSubscription = subscriptions.find(
        (sub) => sub.subscription_type === "lifetimepass" || sub.subscription_type === "yearpass",
      );
      setActiveSubscriptionType(activeSubscription?.subscription_type || null);
    }
  }, [subscriptions]);

  // Memoized layout data
  const startLink = useMemo(() => {
    return sessionRole === "authenticated" ? `../private/${PRIVATE_ROUTES.accueil}` : PUBLIC_ROUTES.index;
  }, [sessionRole]);

  const isPrivateRoute = useMemo(() => matchPath({ path: PRIVATE_ROUTES.private + "/*" }, pathname), [pathname]);

  const layoutData = useMemo(
    () => GetLayoutData(pathname, startLink, isPrivateRoute),
    [pathname, startLink, isPrivateRoute],
  );

  // React Hotkeys Hook
  useHotkeys("alt+t", () => toggleMenu());
  useHotkeys("alt+r", () => toggleTheme());
  useHotkeys("alt+m", () => nav("../../" + PUBLIC_ROUTES.contact));
  useHotkeys("alt+a", () => ScrollTo({ targetId: "main-content", behavior: "smooth" }));

  return (
    <>
      <SectionScrollable>
        <View
          UNSAFE_className={openDyslexic ? "isOpenDyslexic" : ""}
          backgroundColor="gray-100"
          colorVersion={6}
          padding="size-100"
          data-pagefind-weight="9"
          data-pagefind-meta={layoutData?.description}
          minHeight="100vh"
          paddingBottom="size-1000"
        >
          {layoutData && (
            <>
              {/* METADATA */}
              <Seo
                key={`SEO_KEY${pathname}`}
                title={layoutData?.pageTitle}
                description={layoutData?.description}
                name={layoutData?.name}
                type={layoutData?.type}
                keywords={layoutData?.keywords}
                isPrivate={isPrivateRoute}
                pathname={pathname}
              />

              {/* BREADCRUMB */}
              <Breadcrumb breadcrumbItems={layoutData?.breadcrumbItems} />

              {/* ShoppingCart */}
              {isPrivateRoute && !activeSubscriptionType && (
                <DialogTrigger type="tray">
                  <TooltipTrigger>
                    <Button
                      UNSAFE_style={{
                        transform: "rotate(-2deg)",
                        borderRadius: "20% 25% 20% 24%",
                      }}
                      variant="accent"
                      aria-label="Acheter un forfait"
                    >
                      <ShoppingCart aria-label="Caddie" />
                      <Text>Prendre un forfait</Text>
                    </Button>
                    <Tooltip showIcon="true">Prendre un Forfait</Tooltip>
                  </TooltipTrigger>
                  <Suspense fallback={<Loading />}>
                    <SetlazyOffer />
                  </Suspense>
                </DialogTrigger>
              )}

              {/* TITLE H1 */}
              <Heading
                id="main-content"
                marginTop="size-50"
                marginBottom="size-50"
                level={1}
                data-pagefind-meta={layoutData?.title}
                data-pagefind-weight="10"
              >
                {layoutData?.pageTitle}
              </Heading>
              <Text>{layoutData?.subTitle}</Text>

              {/* ERROR HANDLER */}
              <ErrorBoundary fallbackRender={ErrorFallback}>
                <Flex direction="column" marginTop="size-300" justifyContent="center" alignItems="center">
                  {children}
                </Flex>
              </ErrorBoundary>

              {/* FOOTER */}
              {!isPrivateRoute && <PublicFooter />}
            </>
          )}
        </View>
      </SectionScrollable>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
